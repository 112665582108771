import { InfoTwoTone } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

interface ExplanationDialogProps {
  question: string;
  explanation: JSX.Element;
}

const ExplanationDialog = ({
  question,
  explanation,
}: ExplanationDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="text">
        Toelichting & Advies

        <InfoTwoTone fontSize="small" sx={{ ml: 1 }} />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <strong>Vraag: </strong>
          {question}
        </DialogTitle>
        <DialogContent>{explanation}</DialogContent>
      </Dialog>
    </>
  );
};

export default ExplanationDialog;
