import { errorColor, warningColor, successColor } from "../theme";

enum ScoreResult { RED, ORANGE, GREEN }

export const getScoreResult = (percentage: number) => {
    var resultaat: ScoreResult = ScoreResult.GREEN;

    if (percentage < 25)
        resultaat = ScoreResult.RED;
    else if (percentage < 75)
        resultaat = ScoreResult.ORANGE;

    return resultaat;
}

export const getScoreColorFunc = (r: ScoreResult) => {
    switch (r) {
        case ScoreResult.RED:
            return errorColor;
        case ScoreResult.ORANGE:
            return warningColor;
        case ScoreResult.GREEN:
            return successColor;
    }
}

export const getScoreLabel = (r: ScoreResult) => {
    switch (r) {
        case ScoreResult.RED:
            return "Rood";
        case ScoreResult.ORANGE:
            return "Oranje";
        case ScoreResult.GREEN:
            return "Groen";
    }
}

export const getScoreDescription = (r: ScoreResult) => {
    switch (r) {
        case ScoreResult.RED:
        case ScoreResult.ORANGE:
            return "Uw score wijst erop dat u mogelijk niet te werk gaat als een zelfstandige. Ga door met de extra vragen om mogelijke misstander weg te nemen.";
        case ScoreResult.GREEN:
            return "Uw score wijst erop dat u te werk gaat als een zelfstandige. U kunt doorgaan naar de volgende stap om uw rapport te ontvangen.";
    }
}

export const getContraScoreDescription = (r: ScoreResult) => {
    switch (r) {
        case ScoreResult.RED:
        case ScoreResult.ORANGE:
            return "Uw score wijst erop dat u mogelijk niet te werk gaat als een zelfstandige.";
        case ScoreResult.GREEN:
            return "Uw score wijst erop dat u te werk gaat als een zelfstandige.";
    }
}