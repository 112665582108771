export const mainExplanations = [
  <div key="1">
    <h2>Toelichting</h2>
    <p>
      In de wet is “Leiding en Toezicht” een van de drie belangrijkste
      indicatoren voor het bepalen van een dienstverband. Wanneer de
      Belastingdienst constateert dat een zelfstandige werkt onder leiding en
      toezicht van de opdrachtgever, kan dit leiden tot naheffingen van
      loonbelasting, sociale premies en pensioenafdrachten. De opdrachtgever
      wordt in dat geval aansprakelijk gesteld voor deze naheffingen.
    </p>
    <p>
      Bij een overeenkomst van opdracht ontbreekt een gezagsverhouding. De
      opdrachtgever kan wel instructies geven over het gewenste resultaat
      <strong> “WAT”</strong>, maar niet over de wijze waarop de opdrachtnemer
      tot dit resultaat moet komen (<strong>“HOE”</strong>). Als een
      zelfstandige echter onder leiding en toezicht werkt, voldoet de
      arbeidsrelatie aan de definitie van een arbeidsovereenkomst. Dit betekent
      dat de zelfstandige feitelijk als werknemer moet worden beschouwd.
    </p>
    <p>
      In een dienstbetrekking is er altijd sprake van een gezagsverhouding: de
      werkgever heeft het recht om de werknemer aanwijzingen en instructies te
      geven over de uitvoering van het werk (<strong>“HOE”</strong>).
    </p>

    <h2>Advies</h2>
    <p>
      Een zelfstandige mag nooit werken onder leiding en toezicht van de
      opdrachtgever. De opdrachtgever kan enkel beperkte instructies geven over
      het te behalen eindresultaat. De zelfstandige moet de vrijheid hebben om
      zelf te bepalen hoe het werk wordt uitgevoerd, zonder directe aanwijzingen
      of instructies van de opdrachtgever over de uitvoeringswijze. De
      opdrachtgever mag afspraken maken over het te behalen eindresultaat, maar
      niet over de werkwijze om dit te bereiken.
    </p>
    <p>
      Bespreek of de opdracht en omschrijving zodanig aangepast kunnen worden
      dat de uitvoering plaatsvindt zonder leiding en toezicht. Wanneer leiding
      en toezicht ontbreken, maar de professional toch niet wordt erkend als
      zelfstandige of ondernemer voor de inkomstenbelasting, is er sprake van
      schijnzelfstandigheid. In dit geval ligt de bewijslast bij de
      opdrachtgever of het tussenkomstbureau.
    </p>
  </div>,
  <div key="2">
    <h2>Toelichting</h2>
    <p>
      Een inspanningsverplichting wijst op een arbeidsovereenkomst, een
      resultaatverplichting wijst daar juist niet op.
    </p>
    <p>
      Een Zelfstandige neemt een opdracht aan en is vrij om het resultaat naar
      eigen inzicht en voor eigen risico uit te voeren. De instructies moeten
      zich beperken tot overleg over het gewenste resultaat. Overleg mag gevoerd
      worden over de inhoud van het project. Ook tussentijdse resultaten mogen
      geëvalueerd en besproken worden richting het gewenste eindresultaat.
    </p>
    <p>
      Opdragen <strong>HOE</strong> de Zelfstandige het moet uitvoeren (
      <em>=inspanningsverplichting</em>) is niet toegestaan. Dat is een
      indicatie tot een dienstverband.
    </p>
    <p>
      Indien er volgens regulering dient te worden gewerkt is dit{" "}
      <strong>GEEN</strong> directe indicatie tot een dienstverband, zoals
      bijvoorbeeld vanwege veiligheid of de Arbowet.
    </p>

    <h2>Advies</h2>
    <p>
      Controleer of de opdracht uitgevoerd kan worden zonder inhoudelijke
      aansturing. Let op dat er geen bepalingen zijn <strong>HOE</strong> de
      opdracht uitgevoerd dient te worden. De Overeenkomst van Opdracht dient
      een resultaatomschrijving te bevatten zonder bepalingen waar de
      Zelfstandige zich tijdens het uitvoeren van de opdracht aan dient te
      houden. Controleer periodiek of de opdrachtgever zich in de praktijk ook
      niet anders gedraagt.
    </p>
  </div>,
  <div key="3">
    <h2>Toelichting</h2>
    <p>
      Het is van groot belang de duur van de opdracht te duiden waarbinnen men
      tot het beschreven resultaat dient te komen. Bij een overeenkomst van
      opdracht wordt een opdracht aangegaan door de Zelfstandige om uiterlijk op
      de einddatum de beschreven resultaten op te leveren. Een duidelijk
      eindpunt of oplevering duidt op een zelfstandige opdracht, in plaats van
      een doorlopende arbeidsrelatie.
    </p>

    <h2>Advies</h2>
    <p>
      De duur en de einddatum van de opdracht kunnen worden onderbouwd door de
      opdrachtomschrijving en de op te leveren deliverable.
    </p>
    <p>
      Bij een contract waarbij gesproken wordt over een optie tot verlenging
      (met herhaalde vastgestelde periode) hebben begin- en einddatum minder
      betekenis, daar er geen objectief einde is en dat is een indicatie van een
      dienstverband.
    </p>
    <p>
      <strong>Van belang:</strong> indien meer dan 70% van de omzet van 1
      opdrachtgever komt in een langdurige opvolgende periode, dan kan er sprake
      zijn van een economische afhankelijkheid van één opdrachtgever. En dat
      duidt een schijnzelfstandigheid.
    </p>
    <p>
      <strong>Kortom:</strong> algemene begin- en einddatum van een project
      voldoen snel niet!
    </p>
  </div>,
  <div key="4">
    <h2>Toelichting</h2>
    <p>
      Dezelfde werkzaamheden uitvoeren als personeel in loondienst is een
      indicatie voor dienstverband. Tijdelijk vervangen van een werknemer door
      een Zelfstandige is niet per se verboden, maar het is dan wel belangrijk
      dat de Zelfstandige ook daadwerkelijk als Zelfstandige opereert. Met alle
      vrijheid en andere zaken die bij een Zelfstandige horen.
    </p>
    <p>
      Daarnaast kan eerder sprake zijn van een arbeidsovereenkomst als het werk
      een wezenlijk onderdeel vormt van de bedrijfsvoering van de opdrachtgever.
      Het is dan van belang om vast te stellen wat de ondernemingsactiviteiten
      van de opdrachtgever zijn en wat de rol van de opdrachtnemer hierin is. Is
      het werk een wezenlijk onderdeel van de bedrijfsvoering van de
      opdrachtgever of heeft het een structureel karakter? Dan is er volgens de
      jurisprudentie meestal sprake van een arbeidsovereenkomst.
    </p>
    <p>
      De Zelfstandige mag niet hetzelfde behandeld worden als het vaste
      personeel met alle personeelsverplichtingen, beoordelingen en
      personeelsuitjes. Hoe meer de functie of de opdrachtnemer is ingebed in de
      organisatie van de opdrachtgever, hoe meer dat wijst op een
      arbeidsovereenkomst.
    </p>

    <h2>Advies</h2>
    <p>
      Het betreft ingehuurde expertise die anders niet aanwezig is binnen de
      organisatie. Zet een goed beschreven project met concreet eindresultaat op
      papier.
    </p>
    <p>
      Gaat het om vervanging bij ziekte/zwangerschap, inspringen bij
      piekmomenten of om opvulling van een vacature welke werkzaamheden normaal
      gesproken door de werknemers in loondienst bij opdrachtgever worden
      gedaan, dan is het raadzaam om hier een afgebakend project van te maken
      met een kop en een staart en een vaste projectsom.
    </p>
  </div>,
  <div key="5">
    <h2>Toelichting</h2>
    <p>
      Er is sprake van rechtsopvolgend werkgeverschap indien er binnen 6 maanden
      na beëindiging van het dienstverband overgegaan wordt naar uitvoering van
      de werkzaamheden/opdracht als Zelfstandige. In de
      werkgever/werknemer-relatie was sprake van gezag en dan is voortzetting
      gelijkgesteld.
    </p>

    <h2>Advies</h2>
    <p>
      Iemand vanuit loondienst niet direct daarna als Zelfstandige inzetten bij
      dezelfde opdrachtgever (ex-werkgever). Fulltime werken voor de oude
      opdrachtgever is een indicator voor een dienstverband, tenzij ingehuurd
      voor een duidelijk project met een begin en eind bij een andere inhurende
      manager.
    </p>
    <p>
      Tenzij het een langdurig project betreft met dezelfde werkzaamheden, is
      het langdurig (bijvoorbeeld meerdere jaren) werken voor slechts één
      opdrachtgever een indicator voor een dienstverband.
    </p>
    <p>
      Het heeft de voorkeur dat de Zelfstandige niet economisch afhankelijk is
      van één opdrachtgever (<strong>indicatie dienstverband:</strong> meer dan
      70% van de omzet afkomstig van één opdrachtgever). Bij een herhaalde inzet
      bij dezelfde opdrachtgever dient een nieuwe Overeenkomst van Opdracht te
      worden gemaakt (andere opdracht, ander eindresultaat). Voortzetting van
      dezelfde werkzaamheden geeft een indicatie van een dienstverband.
    </p>
  </div>,
  <div key="6">
    <h2>Toelichting</h2>
    <p>
      Diverse (Model)overeenkomsten zijn van toepassing geworden in de periode
      na de VAR. Ook veel overeenkomsten bevatten indicatie naar een
      dienstverband zoals:
      <strong>“ter beschikking stellen”</strong>,{" "}
      <strong>“Inspanningsverplichting”</strong>,{" "}
      <strong>“Detacheringsovereenkomst”</strong>,
      <strong>“Aanneming van werk”</strong>, <strong>“Nulurencontract”</strong>,{" "}
      <strong>“Oproepovereenkomst”</strong>,<strong>“Opzegtermijn”</strong>,{" "}
      <strong>“Concurrentiebeding”</strong>, <strong>“CAO-voorwaarden”</strong>,
      en <strong>“Handboek”</strong>. Allemaal termen die het rechtsvermoeden
      van een (fictief) dienstverband bevestigen.
    </p>
    <p>
      De geldigheid van de Modelovereenkomsten stopt en men kan zich, na de
      afloopdatum van die modelovereenkomst, niet langer beroepen op de daarin
      voorkomende voorwaarden.
    </p>

    <h2>Advies</h2>
    <p>
      Controleer welke modelovereenkomst is toegepast en dat{" "}
      <strong>ALLE</strong> door de Belastingdienst geel gemarkeerde voorwaarden
      <strong>ongewijzigd</strong> (!) in de overeenkomst staan. Is er geen
      modelovereenkomst gebruikt, dan is het advies om er toch een te gebruiken.
    </p>
    <p>
      Leg in een Addendum vast welke afwijkende onderhandelde voorwaarden ook
      van toepassing zijn. Een Zelfstandige heeft de vrijheid om te
      onderhandelen over onder andere zijn tarief. Leg de correspondentie over
      de onderhandeling van het uurtarief vast in het dossier als indicatie voor
      zelfstandigheid.
    </p>
    <p>
      Een Zelfstandige heeft een dusdanig hoge maatschappelijke positie dat het
      aannemelijk is dat hij een gelijkwaardige (onderhandelings)positie heeft
      ten opzichte van de opdrachtgever.
    </p>
    <p>
      Is er sprake van een tussenkomst-situatie, dan is de modelovereenkomst van
      de BOVIB (en het daarbij behorende Keurmerk voor leden) een oriëntatie
      waard.
    </p>
  </div>,
  <div key="7">
    <h2>Toelichting</h2>
    <p>
      Feitelijk loopt elke ondernemer debiteurenrisico. Er kunnen zich
      omstandigheden voordoen dat een ondernemer niet betaald krijgt,
      bijvoorbeeld faillissement van de opdrachtgever, wanprestatie, niet
      nakomen afspraken, etc.
    </p>
    <p>
      Een werknemer kent geen debiteurenrisico en wel een inkomensgarantie
      vanuit het sociaal vangnet.
    </p>
    <p>
      Als de opdrachtnemer zelf facturen stuurt en erop let dat die (op tijd)
      worden betaald, dan wijst dat meer op werken als zzp’er.
    </p>
    <p>
      Als de opdrachtgever automatisch de beloning aan de opdrachtnemer betaalt
      of als de opdrachtgever de facturen voor de opdrachtnemer verzorgt, dan
      kan dat wijzen op bepaalmacht bij de opdrachtgever en daarmee op een vorm
      van gezag (richting arbeidsovereenkomst).
    </p>

    <h2>Advies</h2>
    <p>
      Het risico van niet-betaling kan middels een ketenbeding doorgelegd worden
      naar de opdrachtnemer. Hou wel rekening met de Europese Verordening inzake
      de betaaltermijn van maximaal 30 dagen.
    </p>
    <p>
      Indien men niet overeenkomt dat er een langere betaaltermijn geldt door
      een “keten van debiteuren”, dan kan er sprake zijn van een
      niet-betwistbare rentevergoeding. Tenzij anders overeengekomen waarbij de
      basis van redelijkheid en billijkheid gehanteerd moet worden.
      <strong>Let op:</strong> 45 dagen of langere betaaltermijn is niet meer
      redelijk.
    </p>
    <p>Leg in de overeenkomst vast dat in geval van Reversed-/Self-Billing:</p>
    <ul>
      <li>de facturen geproduceerd worden door de opdrachtgever,</li>
      <li>dat er sprake is van een administratieve verwerking,</li>
      <li>
        opdrachtnemer akkoord is met de administratieve verwerking op basis van
        Reversed-/Self-Billing, waarbij opdrachtnemer zijn eigen gekozen
        factuurnummer op de factuur terug zal zien en dit proces er is in het
        gezamenlijk belang van een ordentelijke administratieve verwerking en
        geen indicatie is van bepaalmacht en/of gezag.
      </li>
    </ul>
  </div>,
  <div key="8">
    <h2>Toelichting</h2>
    <p>
      Een Zelfstandige factureert zijn werk middels een geldige factuur. Hij
      krijgt alleen betaald als hij ook heeft gewerkt aan het resultaat
      gedefinieerd in de Overeenkomst van Opdracht. Hij krijgt niet doorbetaald
      indien hij niet heeft gewerkt.
    </p>

    <h2>Advies</h2>
    <p>
      Controleer de hoogte van het tarief op uurbasis. In hoeverre is de
      beloning van de opdrachtnemer hoger dan van personeel in loondienst? Als
      de beloning van de opdrachtnemer vergelijkbaar is met het loon van het
      personeel in loondienst, dan wijst dat op een arbeidsovereenkomst. Hoe
      hoger de beloning van de opdrachtnemer ten opzichte van de beloning van
      vergelijkbaar personeel in loondienst, hoe meer dat wijst op werken als
      Zelfstandige. Hanteer een marktconforme basis.
    </p>
    <p>
      Controleer de factuur op geldigheid. De volgende vermeldingen moeten
      opgenomen worden op facturen:
    </p>
    <ul>
      <li>het woord ‘factuur’</li>
      <li>
        uw naam, of maatschappelijke benaming, vestigingsadres en nummer KvK
      </li>
      <li>btw- of ondernemingsnummer</li>
      <li>bankrekeningnummer</li>
      <li>plaats waar de factuur werd opgemaakt en factuurdatum</li>
      <li>vervaldatum (datum waarop betaling binnen dient te zijn/komen)</li>
      <li>uniek factuurnummer</li>
      <li>periode van prestaties</li>
      <li>
        de naam (maatschappelijke benaming) van de ontvanger en diens adres
      </li>
      <li>
        beschrijving en hoeveelheid van de geleverde goederen en/of diensten
      </li>
      <li>(eenheids)prijs (en toegekende korting)</li>
      <li>bedrag zonder btw</li>
      <li>btw-percentage en het btw-bedrag per tarief</li>
      <li>het totaal te betalen bedrag</li>
      <li>
        betalingscondities (afgesproken betaaltermijn met rekeningnummer waarop
        betaald moet worden)
      </li>
    </ul>
    <p>
      <strong>Optioneel toevoegen aan een factuur:</strong>
    </p>
    <ul>
      <li>
        verwijzen naar voorgaande facturen, indien het om een deelfactuur gaat
      </li>
      <li>verwijzen naar de algemene voorwaarden</li>
      <li>
        niet verplicht, maar wel verstandig als de voorwaarden deel uitmaken van
        de verkoopovereenkomst; eventueel de voorwaarden opnemen op de
        achterzijde van de factuur
      </li>
    </ul>
    <p>
      <strong>Specifieke btw-vermeldingen op facturen:</strong> Bijvoorbeeld:
    </p>
    <ul>
      <li>
        ‘btw verlegd’, indien de btw verschuldigd is door de medecontractant
      </li>
      <li>‘factuur uitgereikt door afnemer’, in het geval van self-billing</li>
      <li>
        ‘vrijgesteld van btw op grond van artikel …’, indien de handeling
        vrijgesteld is van btw - de wettelijke grondslag voor de vrijstelling
        moet gedetailleerd omschreven zijn
      </li>
      <li>bijzondere vermeldingen opgelegd door wetgeving</li>
    </ul>
    <p>
      <strong>Let op:</strong> Bij Reversed Billing dat het niet op een
      loonstrook lijkt!
    </p>
  </div>,
  <div key="9">
    <h2>Toelichting</h2>
    <p>
      Dit kan problemen opleveren voor zowel de zelfstandige als de
      opdrachtgever, zoals een herkwalificatie van de arbeidsrelatie naar
      loondienst, met mogelijk fiscale en juridische gevolgen.
    </p>
    <p>
      Een beloning op basis van de CAO kan worden gezien als een aanwijzing voor
      een arbeidsovereenkomst, waardoor jouw status als zelfstandige onder druk
      kan komen te staan. Door duidelijke grenzen te stellen en je
      zelfstandigheid te benadrukken, kun je dit risico minimaliseren en je
      zakelijke positie beschermen. Daar er sprake is van het recht tot
      onderhandelen is het verstandig om uitsluitend sector-gerelateerde CAO’s
      te bespreken waarin de belangen van de Zelfstandige benoemd worden en
      opvolging eisen.
    </p>
    <p>
      Van belang hierbij is ook hoeveel invloed de opdrachtgever heeft op het
      aantal opdrachten dat de opdrachtnemer bij andere opdrachtgevers mag
      aannemen.
    </p>
    <p>
      Bij dit gezichtspunt is van belang in hoeverre de opdrachtnemer zich bij
      soortgelijke opdrachten voor andere opdrachtgevers als ondernemer gedraagt
      of kan gedragen. Gedraagt de opdrachtnemer zich als ondernemer? Dan wijst
      dit op werken als Zelfstandige. Denk bijvoorbeeld aan:
    </p>
    <ul>
      <li>de naamsbekendheid van de opdrachtnemer,</li>
      <li>de inspanningen om nieuwe opdrachten te krijgen,</li>
      <li>het aantal opdrachten van andere opdrachtgevers,</li>
      <li>de duur van deze opdrachten en eigen bedrijfskleding.</li>
    </ul>

    <h2>Advies</h2>
    <p>
      Hierbij gaat het onder meer om de verdeling van risico’s tussen de
      opdrachtnemer en de opdrachtgever, bijvoorbeeld bij schade (aan derden),
      ziekte en ongeval. En ook om de mate waarin de opdrachtnemer
      verantwoordelijk is voor de kwaliteit van het resultaat. Moet de
      opdrachtnemer bijvoorbeeld in eigen tijd en voor eigen rekening het
      resultaat verbeteren wanneer dit onder de maat is? Als het risico bij de
      opdrachtnemer ligt, wijst dit op werken als zzp’er. Als het risico bij de
      opdrachtgever ligt, wijst dit op een arbeidsovereenkomst.
    </p>
    <h3>Aandachtspunten:</h3>
    <ul>
      <li>
        <strong>Bespreek het tarief en de voorwaarden:</strong> Zorg ervoor dat
        je tarief duidelijk losstaat van de CAO-lonen van werknemers. Je tarief
        moet gebaseerd zijn op de kosten van jouw zelfstandige ondernemerschap,
        zoals verzekering, administratie, pensioen, en overige bedrijfsrisico's.
        Dit benadrukt je zelfstandige status. Stel een tarief vast dat
        gebruikelijk is voor zelfstandigen in jouw vakgebied en niet enkel op
        wat in de CAO wordt gehanteerd.
      </li>
      <li>
        <strong>Vermijd een directe koppeling met de CAO:</strong> Bespreek met
        de opdrachtgever dat jouw vergoeding niet gekoppeld is aan de CAO van
        het personeel. Dit is belangrijk om te voorkomen dat er een schijn van
        dienstverband ontstaat. Geef aan dat je als ondernemer vrij bent om je
        eigen tarieven te bepalen.
      </li>
      <li>
        <strong>Documenteer zelfstandigheid:</strong> Leg contractueel vast dat
        je een zelfstandige bent, inclusief:
        <ul>
          <li>Het ontbreken van een gezagsverhouding.</li>
          <li>
            Dat je zelf verantwoordelijk bent voor het bepalen van de wijze van
            uitvoering.
          </li>
          <li>
            Dat je niet onder de arbeidsvoorwaarden van de opdrachtgever valt.
          </li>
          <li>
            Gebruik een goedgekeurde modelovereenkomst van de Belastingdienst,
            zoals een overeenkomst zonder werkgeversgezag.
          </li>
        </ul>
        Pas op met een concurrentie- en relatiebeding.
      </li>
      <li>
        <strong>Vermijd structurele integratie:</strong> Werk niet onder
        dezelfde voorwaarden als werknemers (zoals dezelfde werktijden, toegang
        tot bedrijfsvoordelen of werklocaties). Dit versterkt jouw positie als
        zelfstandige.
      </li>
      <li>
        <strong>Zoek (juridisch) advies:</strong> Als de opdrachtgever blijft
        aandringen op een beloning gebaseerd op de CAO, raadpleeg een juridisch
        expert of belastingadviseur gespecialiseerd in arbeidsrecht en
        zzp-structuren. Zij kunnen je helpen bij het opstellen van een
        waterdicht contract en het waarborgen van je zelfstandige status.
      </li>
    </ul>
  </div>,
  <div key="10">
    <h2>Toelichting</h2>
    <p>
      Een Zelfstandige zal als ondernemer minimaal 2x het brutosalaris moeten
      verdienen om zijn onkosten af te dekken. Lagere beloning voor een
      vergelijkbare loonschaal is een indicatie voor schijnzelfstandigheid.
    </p>
    <p>
      De Zelfstandige krijgt een duidelijk hogere beloning voor de werkzaamheden
      en er gelden andere voorwaarden dan de arbeidsvoorwaarden voor werknemers
      die soortgelijk werk in loondienst verrichten, vanwege het
      (ondernemers)risico van de Zelfstandige. Bijvoorbeeld ook
      aansprakelijkheid voor gemaakte fouten/veroorzaakte schade en voor (de
      kwaliteit) van het resultaat van de opdracht.
    </p>
    <p>
      Daarnaast bestaan er kosten voor administratie, verzekeringen, transport
      en reserveringen die voor de Zelfstandige altijd doorgaan. Ook als er geen
      opdracht is.
    </p>

    <h2>Advies</h2>
    <p>
      Hanteer zo veel mogelijk een all-in (uur)tarief. Of nog beter: indien
      mogelijk een fixed price, lump sum of andere afrekenvorm.
    </p>
    <p>
      Er worden geen andere vergoedingen verstrekt dan een all-in uurtarief.
    </p>
    <p>
      Er heerst een bepaalde overtuiging omtrent een minimumtarief. Daaronder
      bestaat het rechtsvermoeden van een dienstverband. Wij adviseren minstens
      35 euro. Ook 33 euro wordt weleens genoemd.
    </p>
  </div>,
  <div key="11">
    <h2>Toelichting</h2>
    <p>
      Werken op eigen rekening en risico is de essentie van het zelfstandige
      ondernemerschap. Dit brengt verschillende risico's met zich mee, zoals
      debiteurenrisico, aansprakelijkheidsrisico en financiële risico's bij het
      niet nakomen van een resultaatverplichting. Het lopen van
      ondernemersrisico’s is een van de indicatoren van zelfstandigheid.
    </p>

    <h2>Advies</h2>
    <p>
      Wanneer er een totaalprijs wordt afgesproken, kan de zelfstandige extra
      winst genereren door de opdracht efficiënter uit te voeren. Tegelijkertijd
      loopt hij het risico op een lagere winstmarge of zelfs verlies als de
      uitvoering niet aan de verwachtingen voldoet en er meer tijd of middelen
      nodig zijn.
    </p>
    <p>
      Daarnaast is er ook financiële verantwoordelijkheid voor het opnieuw
      moeten uitvoeren van een opdracht als het afgesproken resultaat niet
      (voldoende) is behaald.
    </p>
  </div>,
  <div key="12">
    <h2>Toelichting</h2>
    <p>
      Hierbij is relevant hoe de Zelfstandige zich in het economisch verkeer
      gedraagt. Besteedt de Zelfstandige tijd en aandacht aan het verkrijgen van
      nieuwe opdrachtgevers, heeft hij een eigen website, logo/briefpapier e.d.?
      Is de Zelfstandige geregistreerd voor de btw en/of heeft hij recht op
      fiscale voordelen van het ondernemerschap? Is hij ingeschreven bij de KvK?
    </p>
    <p>
      Wat betreft het aantal opdrachtgevers: wanneer sprake is van meerdere
      opdrachtgevers worden het betalings- en continuïteitsrisico verkleind, en
      is de opdrachtnemer minder afhankelijk van een of enkele opdrachtgevers,
      waarmee de zelfstandigheid toeneemt.
    </p>

    <h2>Advies</h2>
    <p>
      Verzamel bedrijfsinformatie zoals een Kamer van Koophandel-uittreksel,
      btw-nummer, vestigingsadres, website, etc.
    </p>
    <p>
      Blijf weg van uitingen op social media die duiden op een positie gelijk
      aan die van een werknemer van de opdrachtgever, zonder vermelding van zijn
      ondernemerschap.
    </p>
  </div>,
  <div key="13">
    <h2>Toelichting</h2>
    <p>
      Gebruik de ondernemerscheck op de website van de Belastingdienst om alle
      ondernemersaspecten van de Zelfstandige te controleren en vast te leggen.
      De ondernemerscheck is niet leidend maar wel indicatief voor het
      ondernemerschap van een Zelfstandige.
    </p>

    <h2>Advies</h2>
    <p>
      Als er gebruik wordt gemaakt van een bedrijf dat vanuit een specifieke rol
      de overeenkomsten regelt of als acquisiteur werkt voor jouw bedrijf of een
      derde, besef dan dat dit de juridische opdrachtgever is!
    </p>
    <p>
      De overeenkomst van opdracht is een overeenkomst die de relatie tussen een
      opdrachtgever en een opdrachtnemer regelt. De opdrachtgever verplicht zich
      om te betalen voor die diensten die de opdrachtnemer gaat leveren. Er is
      geen sprake van loondienst. De opdracht dient zelfstandig te worden
      uitgevoerd door de opdrachtnemer. De overeenkomst van opdracht is in de
      wet geregeld in de artikelen 7:400 e.v. van het Burgerlijk Wetboek.
    </p>
    <p>
      Raadpleeg een juridisch expert of belastingadviseur gespecialiseerd in
      arbeidsrecht en zzp-structuren. Zij kunnen je helpen bij het waarborgen
      van je zelfstandige status.
    </p>
  </div>,
  <div key="14">
    <h2>Toelichting</h2>
    <p>
      De webmodule Arbeidsrelatie is indicatief, zeer discutabel en heeft geen
      juridische status. De webmodule ziet alleen op een directe relatie tussen
      Opdrachtgever en Zelfstandige. Het is niet mogelijk om een oordeel te
      ontvangen bij inzet van een Zelfstandige bij de Opdrachtgever via een
      tussenkomstbureau.
    </p>
    <p>
      De webmodule is naar het oordeel van de Bovib geen goede indicator voor
      het beoordelen van de arbeidsrelatie. Zolang de praktijksituatie maar
      identiek is aan hetgeen is afgesproken in de Bovib Modelovereenkomst en in
      de praktijk ook volgens de overeenkomst wordt gewerkt (
      <strong>beoordeel dit periodiek!</strong>) is deze voor de hele
      geldigheidsperiode leidend.
    </p>
    <p>
      Zelfs in het uitzonderlijke geval dat de Webmodule een positieve uitslag
      geeft voor "werken als zelfstandige," is het absoluut niet gegarandeerd
      dat dat ook zo is. De afweging gebeurt op basis van alle 9 (10) Deliveroo
      criteria, in onderling verband beschouwd. Voor zover bekend hanteert de
      Webmodule niet de Deliveroo criteria.
    </p>
    <p>
      In het uitzonderlijke geval dat de Webmodule het oordeel "niet geschikt
      voor ZZP" geeft, moet een andere contractvorm worden gekozen.
    </p>

    <h2>Advies</h2>
    <p>
      Raadpleeg een (Bovib-)expert die gespecialiseerd is in arbeidsrecht en
      ZZP-structuren. Vanzelfsprekend zijn de mensen van Compliance Factory dat
      en kunnen wij je helpen bij het waarborgen van je zelfstandige status.
    </p>
  </div>,
];

export const contraExplanations = [
  <div key="1">
    <h2>Gehoorzaamheid en gezag</h2>
    <p>
      Het aangeven hoe de werkzaamheden verricht dienen te worden is een heldere
      indicatie van gezag.
    </p>
    <p>
      Vermijd een dergelijke duiding dan ook in de praktijk en in de
      overeenkomst. Uiteindelijk gaat het in de praktijk ook om het resultaat,
      ongeacht wat men beoogt, en zou het niet al te moeilijk moeten zijn om het
      te duiden en te verbinden aan afspraken welke uiteindelijk het beoogde
      resultaat borgen.
    </p>
  </div>,
  <div key="2">
    <h2>Eenvoudige werkzaamheden en arbeidsovereenkomst</h2>
    <p>Eenvoudige werkzaamheden kunnen wijzen op een arbeidsovereenkomst.</p>
    <p>
      De aard van het werk kan betekenen dat er weinig of geen aanwijzingen
      nodig zijn.
    </p>
    <p>
      Bijvoorbeeld als het werk heel eenvoudig is (koek inpakken of aardbeien
      plukken). Als er in die situaties ook daadwerkelijk weinig of geen
      aanwijzingen worden gegeven, dan heeft dat niet veel betekenis, omdat de
      aard van het werk dit met zich meebrengt. En kan dat een indicatie van een
      arbeidsverhouding zijn.
    </p>
  </div>,
  <div key="3">
    <h2>Locatie en tijdstip als indicatie</h2>
    <p>
      Als een locatie bepalend is om de werkzaamheden te kunnen verrichten, dan
      is dat niet per definitie een indicatie van een dienstverband. Dat geldt
      ook voor het tijdstip.
    </p>
    <p>
      Een tijdstip kan en mag om veiligheidsredenen bepaald worden door de
      opdrachtgever. Laat duidelijk in de opdracht blijken wat de bedoeling is
      van de locatie.
    </p>
  </div>,
  <div key="4">
    <h2>Verlof en afwezigheid als indicatie</h2>
    <p>
      Als de opdrachtnemer géén verplichting heeft om verlof of afwezigheid door
      te geven, wijst dit erop dat er géén gezagsrelatie is die normaal
      kenmerkend is voor een dienstverband en een positieve indicatie voor
      zelfstandige ondernemer.
    </p>
  </div>,
  <div key="5">
    <h2>Toegang tot organisatiebronnen</h2>
    <p>
      Beperkte toegang tot organisatiebronnen toont aan dat de opdrachtnemer
      geen volwaardige rol binnen de organisatie heeft, wat past bij een
      zelfstandige relatie.
    </p>
    <p>
      Bestaat die (verplichte) toegang wel en het vloeit voort uit
      beveiligingsmaatregelen, dan is het verbonden aan de locatie en is dit
      niet per definitie een indicator naar een dienstverband.
    </p>
    <p>
      Beschrijf in de overeenkomst of verwijs naar een document dat de
      beveiliging benadrukt.
    </p>
  </div>,
  <div key="6">
    <h2>Functioneringsgesprekken en zelfstandigheid</h2>
    <p>
      Het ontbreken van functioneringsgesprekken wijst erop dat de opdrachtgever
      niet functioneel toezicht houdt op de opdrachtnemer, wat kenmerkend is
      voor zelfstandigheid.
    </p>
    <p>
      Beperk het gesprek tot het doornemen van de gerealiseerde afspraken zoals
      ondernemers dat geacht worden te doen.
    </p>
    <p>
      Een eventueel verslag dient <strong>NIET</strong> opgenomen te worden als
      “Beoordelingsgesprek” of in “een personeelsdossier”. Leg dit desnoods vast
      in de overeenkomst.
    </p>
  </div>,
  <div key="7">
    <h2>Persoonlijke uitvoering en arbeidsovereenkomst</h2>
    <p>
      Als de opdrachtnemer het werk persoonlijk moet uitvoeren en zich niet
      zonder toestemming van de opdrachtgever mag laten vervangen, dan wijst dit
      direct op een arbeidsovereenkomst.
    </p>
    <p>
      In de wet staat ook dat een dienstverband gebaseerd is op 3 criteria:{" "}
      <strong>Gezag</strong>, <strong>Arbeid</strong> en <strong>Loon</strong>.
    </p>
    <p>
      Arbeid is hier wettelijk bepaald: de werknemer is verplicht (persoonlijk)
      arbeid te verrichten.
    </p>
  </div>,
  <div key="8">
    <h2>Flexibiliteit en autonomie in samenwerking</h2>
    <p>
      Dit gaat over de mate van flexibiliteit en autonomie binnen de
      samenwerking tussen de opdrachtgever en de zelfstandige.
    </p>
    <p>
      Een overeenkomst van opdracht kent geen garantie of verplichting tot
      verlenging. Een vastgelegde looptijd zonder garantie op verlenging is
      typisch voor zelfstandige opdrachten en toont aan dat er geen sprake is
      van een langdurige arbeidsrelatie.
    </p>
    <p>
      Een langdurige (meerdere jaren) inzet middels een overeenkomst van
      opdracht is mogelijk indien het een langdurig project betreft en de
      zelfstandige een langere tijd nodig heeft om het beschreven resultaat in
      dit project te realiseren.
    </p>
    <p>
      Tijdens de opdracht wordt er regelmatig stilgestaan bij de voortgang en
      resultaten. Dit biedt ruimte om te bespreken of het werk volgens
      verwachting verloopt.
    </p>
    <p>
      Op basis van de evaluatie kan besloten worden om de opdrachtperiode te
      verlengen, indien er meer werk nodig is of als de samenwerking succesvol
      is.
    </p>
    <p>
      Als de omstandigheden of behoeften van de opdrachtgever veranderen, kan de
      opdracht worden uitgebreid of aangepast, bijvoorbeeld door nieuwe doelen
      of taken toe te voegen.
    </p>
    <ul>
      <li>
        De aanpassing gebeurt in overleg en schriftelijk wordt vastgelegd.
      </li>
      <li>
        De zelfstandige de vrijheid heeft om nieuwe voorwaarden (zoals tarief of
        planning) te onderhandelen.
      </li>
      <li>
        De aanpassing geen indicatie geeft van een gezagsverhouding, zoals het
        verplicht aannemen van nieuwe taken zonder instemming.
      </li>
    </ul>
    <p>
      Het benadrukt de zakelijke relatie, waarbij beide partijen flexibel kunnen
      omgaan met veranderende omstandigheden.
    </p>
  </div>,
  <div key="9">
    <h2>Zelfstandigheid of arbeidsovereenkomst?</h2>
    <p>
      De vraag is bedoeld om te achterhalen of de aard van de opdracht wijst op
      zelfstandigheid of op een mogelijke arbeidsovereenkomst.
    </p>
    <p>
      De zelfstandige wordt ingehuurd om een duidelijk afgebakend doel of
      project te realiseren, met vooraf bepaalde resultaten. Dit kan
      bijvoorbeeld een adviesrapport, een IT-implementatie of een renovatie
      zijn. De opdracht staat los van het dagelijkse werk dat een vaste
      medewerker normaal zou doen. Het gaat niet om het structureel uitvoeren
      van bijvoorbeeld administratieve taken of het vullen van een openstaande
      functie.
    </p>
    <p>
      De volgende subvragen kan men zich stellen en de antwoorden die de
      zelfstandigheid bevestigen opnemen in de overeenkomst: (een positief
      antwoord duidt zelfstandigheid):
    </p>
    <ul>
      <li>
        De zelfstandige heeft een duidelijk afgebakende taak met een
        projectmatig karakter.
      </li>
      <li>
        De zelfstandige werkt doelgericht aan een eindresultaat en wordt niet
        ingeschakeld om reguliere, routinematige taken over te nemen.
      </li>
    </ul>
    <p>
      Dit duidt op een zakelijke relatie waarin de zelfstandige zelf bepaalt hoe
      het resultaat wordt bereikt.
    </p>
    <p>
      Bij een negatief antwoord (risico op schijnzelfstandigheid): als de
      opdracht bestaat uit het overnemen van reguliere taken van een medewerker
      (bijvoorbeeld tijdens ziekte of verlof), is er een verhoogd risico dat de
      Belastingdienst dit als een arbeidsovereenkomst beschouwt. In dat geval
      kan sprake zijn van integratie in de organisatie, wat kan wijzen op een
      gezagsverhouding.
    </p>
    <p>
      Wij adviseren de opdracht duidelijk in het contract te specificeren. Leg
      vast dat het gaat om een project of resultaat, met concrete deliverables
      en een begin- en einddatum.
    </p>
    <ul>
      <li>
        Vermijd structurele integratie: Zorg ervoor dat de zelfstandige niet
        dezelfde rol vervult als een vaste medewerker of deelneemt aan interne
        processen zoals vergaderingen of werkroosters.
      </li>
      <li>
        Onderbouw het projectmatige karakter: benoem waarom de opdracht
        specifiek is en hoe deze afwijkt van reguliere bedrijfsactiviteiten.
      </li>
    </ul>
    <p>
      Dit helpt om de zelfstandige status van de opdrachtnemer juridisch en
      fiscaal te beschermen.
    </p>
  </div>,
  <div key="10">
    <h2>Zelfstandigheid bij een (ex-)werkgever</h2>
    <p>
      Het vraagstuk van zelfstandigheid bij een (ex-)werkgever is complex, omdat
      het risico op schijnzelfstandigheid erg groot is. De Belastingdienst en
      rechters zullen kritisch(er) kijken naar de omstandigheden waarin de
      opdracht wordt uitgevoerd. Zelfs vrijwillig ontslag en economische
      noodzaak van de ex-werkgever bieden geen garantie dat een zzp-constructie
      wordt geaccepteerd. Echter, er zijn enkele punten en jurisprudentie die
      mogelijk een onderbouwing kunnen bieden voor zelfstandigheid.
    </p>
    <h3>Relevante jurisprudentie</h3>
    <h4>HR 14 november 2014 (X/Gemeente Amsterdam)</h4>
    <p>
      In deze zaak werd vastgesteld dat een zelfstandige status kan worden
      aangenomen, mits er geen sprake is van een gezagsverhouding. Het feit dat
      iemand eerder in loondienst werkte, betekent niet automatisch dat een
      nieuwe overeenkomst ook een dienstbetrekking is, zolang:
    </p>
    <ul>
      <li>De nieuwe samenwerking significant anders is ingericht.</li>
      <li>
        De zelfstandige werkt met duidelijke zelfstandigheid (eigen werktijden,
        eigen middelen, en resultaatsverantwoordelijkheid).
      </li>
    </ul>
    <h4>HR Groen/Schoevers (1997)</h4>
    <p>
      Dit arrest benadrukt dat de kwalificatie van een arbeidsrelatie afhangt
      van de feitelijke uitvoering en niet alleen van de overeenkomst. Indien de
      uitvoering duidelijk kenmerken van ondernemerschap bevat (zoals eigen
      verantwoordelijkheid, vrijheid van uitvoering, en geen hiërarchische
      relatie), kan dit wijzen op zelfstandigheid.
    </p>
    <h4>ECLI:NL:GHARL:2022:4616</h4>
    <p>
      In deze zaak werd gekeken naar een zzp'er die bij zijn voormalige
      werkgever werkte. Het hof oordeelde dat een zzp-constructie mogelijk was,
      mits:
    </p>
    <ul>
      <li>
        Er geen sprake was van dezelfde werkzaamheden als tijdens het
        dienstverband.
      </li>
      <li>
        De zelfstandige nu functioneerde met eigen tariefstelling, middelen, en
        risico’s.
      </li>
      <li>
        De samenwerking als zakelijk en tijdelijk kon worden gekarakteriseerd.
      </li>
    </ul>
    <p>
      Hoewel vrijwillig ontslag op zichzelf geen doorslaggevende factor is, kan
      het wel bijdragen aan de argumentatie dat:
    </p>
    <ul>
      <li>
        De werknemer bewust en vrijwillig voor een ondernemerschap heeft
        gekozen.
      </li>
      <li>
        De voortzetting van de samenwerking plaatsvindt onder nieuwe, zakelijke
        voorwaarden.
      </li>
    </ul>
    <p>
      Echter, dit moet worden ondersteund door concrete feiten die
      zelfstandigheid aantonen.
    </p>
    <h3>Economische noodzaak voor de ex-werkgever</h3>
    <p>
      Dat de ex-werkgever economische schade wil voorkomen, kan juridisch geen
      zelfstandigheid rechtvaardigen. Wel kan het dienen als zakelijke motivatie
      voor het aangaan van een zzp-constructie. Belangrijk hierbij is dat:
    </p>
    <ul>
      <li>
        De ex-werkgever niet dicteert hoe en wanneer de werkzaamheden worden
        uitgevoerd.
      </li>
      <li>
        De ex-werknemer geen exclusieve opdrachten accepteert van de voormalige
        werkgever.
      </li>
    </ul>
    <h3>Ons advies aan de zelfstandige en ex-werkgever</h3>
    <ul>
      <li>
        <strong>Herdefinieer de werkzaamheden:</strong>
        <ul>
          <li>
            Zorg dat de zzp-opdracht duidelijk afwijkt van de taken in
            loondienst.
          </li>
          <li>Focus op projectmatige of resultaatgerichte werkzaamheden.</li>
        </ul>
      </li>
      <li>
        <strong>Stel een waterdichte overeenkomst op:</strong>
        <ul>
          <li>
            Gebruik een modelovereenkomst die goedgekeurd is door de
            Belastingdienst.
          </li>
          <li>
            Leg vast dat er geen sprake is van een gezagsverhouding of
            integratie in de organisatie.
          </li>
        </ul>
      </li>
      <li>
        <strong>Creëer duidelijke zelfstandigheidskenmerken:</strong>
        <ul>
          <li>Eigen tariefstelling.</li>
          <li>Geen exclusiviteit (meerdere opdrachtgevers).</li>
          <li>Gebruik van eigen middelen.</li>
        </ul>
      </li>
      <li>
        <strong>Documenteer de transitie:</strong>
        <ul>
          <li>
            Onderbouw schriftelijk waarom de zzp-constructie zakelijk
            noodzakelijk is.
          </li>
          <li>Benoem de verschillen tussen de oude en nieuwe werkrelatie.</li>
        </ul>
      </li>
    </ul>
    <p>
      Laat de situatie toetsen door een specialist om risico’s op
      schijnzelfstandigheid te minimaliseren. Door deze stappen te volgen en de
      jurisprudentie als referentie te gebruiken, kunnen de zelfstandige en
      ex-werkgever hun samenwerking beter onderbouwen. Toch blijft het een grijs
      gebied en toetsing door instanties zoals de Belastingdienst blijft
      mogelijk.
    </p>
  </div>,
  <div key="11">
    <h2>Aard van de opdracht: zelfstandigheid of dienstverband?</h2>
    <p>
      Deze vraag onderzoekt of de aard van de opdracht wijst op zelfstandigheid
      of een mogelijke gelijkstelling aan een dienstverband. Het gaat erom te
      bepalen of de zelfstandige wordt ingehuurd om een unieke, afgebakende taak
      te vervullen (specifiek project of resultaat) of om de reguliere taken van
      een medewerker over te nemen (wat kan wijzen op schijnzelfstandigheid
      omdat er sprake is van “inbedding”).
    </p>
    <p>
      Als de opdracht een projectmatig karakter heeft met een duidelijk
      gedefinieerd resultaat is er een indicatie van zelfstandigheid, omdat het
      losstaat van de normale operationele taken binnen de organisatie. Het
      toont aan dat de zelfstandige als ondernemer functioneert, gericht op een
      einddoel. Het vermindert het risico op een gezagsverhouding, omdat de
      zelfstandige verantwoordelijk is voor het behalen van een concreet
      resultaat.
    </p>
    <p>
      Deze uitgangspunten helpen om schijnzelfstandigheid te weerleggen, mits er
      ook andere zelfstandigheidscriteria worden voldaan.
    </p>
    <p>
      Indien de opdracht zich richt op het uitvoeren van reguliere taken, zoals
      die ook door een medewerker in loondienst worden uitgevoerd, kan dit
      wijzen op een voortzetting van een dienstverband of gezagsverhouding. Dat
      verhoogt het risico dat de zelfstandige als werknemer wordt beschouwd door
      de Belastingdienst. Het kan duiden op structurele integratie in de
      organisatie en een gebrek aan ondernemerschap.
    </p>
    <p>
      Dit verdient overigens een nuancering. Indien de reguliere taken slechts
      tijdelijk en incidenteel worden overgenomen en er duidelijke
      zelfstandigheidskenmerken aanwezig zijn (zoals eigen middelen, meerdere
      opdrachtgevers, etc.), kan het risico worden beperkt.
    </p>
    <p>
      Het positieve deel ondersteunt de zelfstandige status en geeft aan dat de
      samenwerking projectmatig en zakelijk is. De negatieve kant vraagt om
      aanvullende maatregelen om de zelfstandigheid aan te tonen en het risico
      op schijnzelfstandigheid te verminderen.
    </p>
  </div>,
  <div key="12">
    <h2>Risico’s en uitgaven als indicator voor ondernemerschap</h2>
    <p>
      Wanneer de opdrachtnemer zelf verantwoordelijk is voor de risico’s en
      uitgaven die gepaard gaan met de opdracht, wijst dit op een zelfstandig
      ondernemerschap.
    </p>
    <p>
      Neem in de overeenkomst een aansprakelijkheid op en controleer de
      verzekeringen die hier bijpassen. Zorg wel dat de onderneming van de
      Zelfstandige verzekerd is. Het is een essentiële indicator voor
      ondernemerschap.
    </p>
  </div>,
  <div key="13">
    <h2>Onafhankelijkheid en expertise van de zelfstandige</h2>
    <p>
      Deze vraag is cruciaal om te beoordelen of de zelfstandige daadwerkelijk
      functioneert als ondernemer en niet afhankelijk is van de opdrachtgever
      zoals een werknemer. Een positief antwoord versterkt de positie van de
      zelfstandige, terwijl een negatief antwoord vraagt om heroverweging van de
      samenwerking of aanpassing van de voorwaarden om de zelfstandigheid te
      waarborgen.
    </p>
    <ul>
      <li>
        De zelfstandige beschikt over de benodigde expertise, vaardigheden en
        ervaring om de opdracht zelfstandig uit te voeren zonder intensieve
        begeleiding of afhankelijkheid van de opdrachtgever.
      </li>
      <li>
        Het toont aan dat de zelfstandige als een specialist wordt ingehuurd,
        wat kenmerkend is voor ondernemerschap.
      </li>
      <li>
        Het minimaliseert het risico op een gezagsverhouding, omdat de
        zelfstandige autonoom werkt en niet afhankelijk is van voortdurende
        sturing of toezicht van de opdrachtgever.
      </li>
      <li>
        Het benadrukt de zelfstandige status en vergroot de geloofwaardigheid
        van de zzp-constructie.
      </li>
      <li>
        Als de zelfstandige incidenteel advies of feedback vraagt aan de
        opdrachtgever, betekent dit niet dat er automatisch een gezagsverhouding
        ontstaat, zolang de zelfstandige de regie over de uitvoering behoudt.
      </li>
    </ul>
    <h3>De andere kant van het verhaal (in geval van “Nee”):</h3>
    <ul>
      <li>
        De zelfstandige mist (gedeeltelijk) de kennis of kunde om de opdracht
        volledig zelfstandig uit te voeren en is afhankelijk van de
        opdrachtgever voor instructies, begeleiding, of training.
      </li>
      <li>
        Het vergroot het risico dat er sprake is van een gezagsverhouding, wat
        kan wijzen op schijnzelfstandigheid of de opdrachtgever lijkt in dit
        geval de zelfstandige te integreren in de organisatie, wat duidt op een
        werknemersrol.
      </li>
      <li>
        Het roept twijfel op over het ondernemerschap en de kwaliteiten van de
        zelfstandige, omdat zelfstandigen normaliter worden ingehuurd vanwege
        hun specifieke expertise.
      </li>
    </ul>
    <p>
      Wij adviseren te allen tijde in de overeenkomst een clausule op te nemen
      die de zelfstandige aansprakelijk kan stellen voor het niet leveren van de
      afgesproken resultaten, waarbij de onderneming/opdrachtnemer in staat
      wordt gesteld om alsnog conform overeenkomst/afspraken te leveren.
    </p>
  </div>,
  <div key="14">
    <h2>Zelfstandigheid en werkoverleggen</h2>
    <p>
      Deze vraag speelt een sleutelrol in het beoordelen van de zelfstandigheid.
      Een positief antwoord bevestigt dat de zelfstandige autonoom werkt en
      verantwoordelijk is voor de uitvoering, wat cruciaal is om te voldoen aan
      de criteria voor een zakelijke samenwerking. Een negatief antwoord vraagt
      om aanpassing van de samenwerking, zoals meer zelfstandigheid in de
      uitvoering, om het risico op schijnzelfstandigheid te verkleinen.
    </p>
    <ul>
      <li>
        De werkoverleggen zijn uitsluitend gericht op het bespreken van doelen,
        voortgang en het gewenste eindresultaat. De zelfstandige behoudt
        volledige autonomie over de wijze waarop hij de opdracht uitvoert.
      </li>
      <li>
        Het benadrukt dat de zelfstandige verantwoordelijkheid draagt voor het
        proces en de uitvoering, wat een kenmerk is van ondernemerschap.
      </li>
      <li>
        Er is geen sprake van een gezagsverhouding of micromanagement door de
        opdrachtgever.
      </li>
      <li>
        Dit ondersteunt de zelfstandige status, omdat de zelfstandige niet
        functioneert als werknemer maar als expert die zelf bepaalt hoe de
        opdracht wordt uitgevoerd.
      </li>
      <li>
        Als de opdrachtgever incidenteel suggesties doet, is dat toegestaan,
        zolang dit niet leidt tot structurele aanwijzingen over de uitvoering.
      </li>
    </ul>
    <p>
      Wordt in de werkoverleggen niet alleen het 'wat' besproken, maar ook
      gedetailleerde aanwijzingen gegeven over 'hoe' de zelfstandige zijn werk
      moet uitvoeren, dan wijst dit op een gezagsverhouding, omdat de
      opdrachtgever sturing geeft aan de manier van werken, vergelijkbaar met
      een arbeidsrelatie.
    </p>
    <ul>
      <li>
        De zelfstandige lijkt geïntegreerd in het interne proces, wat kan duiden
        op een schijnzelfstandige constructie.
      </li>
      <li>
        Dit verhoogt het risico dat de Belastingdienst of een rechter de relatie
        als loondienst beschouwt.
      </li>
    </ul>
    <p>
      Als de aanwijzingen uitsluitend betrekking hebben op veiligheidseisen,
      wettelijke verplichtingen, of het goed functioneren binnen een
      projectteam, kan dit legitiem zijn. Echter, het moet duidelijk blijven dat
      de zelfstandige de regie heeft over de uitvoering.
    </p>
  </div>,
  <div key="15">
    <h2>Beëindiging van een opdracht</h2>
    <p>
      Een opdracht kan worden beëindigd zodra de afgesproken doelen zijn
      behaald, ook als dat eerder gebeurt dan verwacht. Flexibiliteit in het
      beëindigen van de opdracht zodra het doel is bereikt geeft aan dat het
      werk projectmatig is, wat kenmerkend is voor zelfstandige contracten.
    </p>
    <p>
      Vaak wordt de vraag gesteld waarom de opdrachtnemer minder mogelijkheden
      tot beëindiging heeft dan de opdrachtgever. Dit recht der partijen is
      vastgelegd in het Burgerlijk Wetboek (artikel 7:408 lid 1 BW), maar er
      zijn belangrijke nuances die van invloed kunnen zijn op hoe en onder welke
      voorwaarden dit recht kan worden uitgeoefend.
    </p>
    <h3>Artikel 7:408 BW:</h3>
    <ul>
      <li>De opdrachtgever kan te allen tijde de overeenkomst opzeggen.</li>
      <li>
        De opdrachtnemer die de overeenkomst is aangegaan in de uitoefening van
        een beroep of bedrijf, kan, behoudens gewichtige redenen, de
        overeenkomst slechts opzeggen, indien zij voor onbepaalde duur geldt en
        niet door volbrenging eindigt.
      </li>
      <li>
        Een natuurlijk persoon die een opdracht heeft verstrekt anders dan in de
        uitoefening van een beroep of bedrijf, is, onverminderd artikel 406, ter
        zake van een opzegging geen schadevergoeding verschuldigd.
      </li>
    </ul>
    <p>
      Uitsluiting of beperking van tussentijdse beëindiging is mogelijk als er
      in de overeenkomst specifieke afspraken zijn gemaakt over opzegging, zoals
      een opzegtermijn, overdracht of voorwaarden voor beëindiging. Deze
      afspraken gaan in veel gevallen boven de algemene wettelijke regeling.
    </p>
    <p>
      Als de overeenkomst het recht op tussentijdse opzegging volledig uitsluit,
      kan dit onredelijk zijn, vooral als de zelfstandige in de praktijk grote
      nadelen ondervindt.
    </p>
    <h3>Bij de beëindiging van een overeenkomst moet de zelfstandige:</h3>
    <ul>
      <li>
        Redelijk handelen en de opdrachtgever niet onverwacht benadelen,
        bijvoorbeeld door zonder aankondiging of te korte termijn te stoppen met
        een cruciale opdracht.
      </li>
      <li>
        Het is verstandig om de beëindiging schriftelijk en gemotiveerd te
        communiceren.
      </li>
    </ul>
    <p>
      Het recht op tussentijdse beëindiging benadrukt de zelfstandige status.
      Een zelfstandige heeft in tegenstelling tot een werknemer geen vaste
      arbeidsrelatie en kan besluiten om een opdracht te stoppen. Indien
      opzegging is uitgesloten kan dat als een indicatie worden gezien dat er
      sprake is van een gezagsverhouding of afhankelijkheid, wat het
      ondernemerschap ondermijnt.
    </p>
    <p>
      Wij adviseren de overeenkomst te voorzien van een duidelijke overdracht en
      te bekijken of er specifieke afspraken zijn over tussentijdse opzegging.
      Volg deze afspraken om conflicten te voorkomen. Bespreek met de
      opdrachtgever of een aanpassing van de samenwerking mogelijk is.
      Tussentijdse beëindiging is immers een recht, maar moet wel zorgvuldig
      worden toegepast om professionele relaties te beschermen.
    </p>
  </div>,
  <div key="16">
    <h2>Gebruik van eigen apparatuur en materialen</h2>
    <p>
      Als de opdrachtnemer zijn of haar eigen apparatuur of gereedschap
      gebruikt, versterkt dit het beeld van zelfstandigheid, wat bijdraagt aan
      het vermijden van een gezagsrelatie.
    </p>
    <p>
      Worden de materialen van een ander gebruikt, dan is het betalen van een
      vergoeding daarvoor (mede verdisconteerd en/of geduid op de factuur) een
      indicatie dat er sprake is van zelfstandig ondernemerschap.
    </p>
    <p>
      Een zelfstandige is een ondernemer en gebruikt voor zijn werkzaamheden een
      eigen laptop, telefoon en alle andere noodzakelijke materialen voor het
      uitvoeren van de Overeenkomst van Opdracht. Het door opdrachtgever
      tijdelijk verstrekken van een hulpmiddel noodzakelijk voor een beveiligde
      toegang (bijvoorbeeld laptop van de Belastingdienst of Defensie) is
      toegestaan.
    </p>
    <p>
      Controleer dat als er middelen door een opdrachtgever worden verstrekt ter
      uitvoering van de Overeenkomst van Opdracht dit echt noodzakelijk is.
      Documenteer de reden(en). Laat indien mogelijk de zelfstandige betalen
      voor het gebruik van hulpmiddelen van de opdrachtgever en compenseer dat
      op de factuur.
    </p>
    <p>
      Gedrag als ondernemer ziet men bij de Belastingdienst als volgt: een eigen
      website, visitekaartjes, emailadres, vermelden van opdrachten op LinkedIn
      en andere social media via diens "bedrijfsnaam".
    </p>
    <p>
      Het ontbreken van een Kamer van Koophandel registratie of BTW nummer geeft
      een sterke indicatie voor schijnzelfstandigheid.
    </p>
  </div>,
  <div key="17">
    <h2>Meerdere opdrachtgevers als indicatie van zelfstandigheid</h2>
    <p>
      Als de opdrachtnemer niet exclusief voor één opdrachtgever werkt, maar ook
      andere klanten heeft, duidt dit op een onafhankelijke bedrijfsvoering
      zonder gezagsverhouding.
    </p>
    <p>
      Het hebben van meerdere opdrachtgevers bevestigt de vrijheid om opdrachten
      aan te nemen die geen invloed hebben op deze opdracht. Het hebben van
      meerdere opdrachtgevers wijst erop dat de opdrachtnemer niet exclusief
      afhankelijk is van deze opdrachtgever, wat typerend is voor een
      zelfstandige positie.
    </p>
    <p>
      Het hebben van meerdere opdrachtgevers toont aan dat de zelfstandige geen
      exclusieve arbeidsrelatie met deze opdrachtgever heeft, wat de indruk van
      zelfstandigheid versterkt.
    </p>
  </div>,
  <div key="18">
    <h2>Zelfstandige administratie en facturatie</h2>
    <p>
      Een opdrachtnemer die zelfstandig factureert en zijn eigen administratie
      bijhoudt, werkt als ondernemer en niet onder het gezag van een
      opdrachtgever.
    </p>
    <p>
      Ook het uitbesteden van de administratie valt hieronder, hetgeen het geval
      kan zijn bij de inzet van een boekhouder/accountant. Het is een indicatie
      van zelfstandig ondernemer.
    </p>
  </div>,
  <div key="19">
    <h2>Specialistische kennis en zelfstandigheid</h2>
    <p>
      De zelfstandige wordt ingehuurd vanwege specialistische kennis of
      vaardigheden die niet aanwezig zijn binnen de organisatie van de
      opdrachtgever. De zelfstandige biedt toegevoegde waarde door als externe
      expert specifieke problemen op te lossen of projecten te realiseren.
    </p>
    <p>
      Dit bevestigt dat de zelfstandige als ondernemer wordt ingehuurd, omdat
      hij/zij expertise levert die niet intern beschikbaar is. Deze insteek
      verkleint het risico op schijnzelfstandigheid, omdat de zelfstandige wordt
      ingehuurd om te voldoen aan een specifieke behoefte, niet om reguliere
      werkzaamheden over te nemen.
    </p>
    <p>
      De zelfstandige is ingehuurd om werkzaamheden uit te voeren waarvoor de
      opdrachtgever wél kennis of vaardigheden in huis heeft. Dit kan betekenen
      dat de zelfstandige feitelijk optreedt als verlengstuk van de
      opdrachtgever of een medewerker vervangt. Als de opdrachtgever zelf de
      kennis of kunde heeft, maar de zelfstandige routinematig werk uitvoert,
      kan dit duiden op een werknemersrelatie. Het wijst op een
      afhankelijkheidsrelatie waarin de zelfstandige meer uitvoert dan
      initieert.
    </p>
    <h3>
      De overwegingen legt men bij voorkeur vast in de overeenkomst en zijn:
    </h3>
    <ul>
      <li>
        Is de zelfstandige gevraagd vanwege specifieke expertise (bijv.
        IT-specialist, jurist, trainer)?
      </li>
      <li>
        Of gaat het om taken die elke medewerker met basiskennis zou kunnen
        uitvoeren?
      </li>
      <li>
        Stelt de opdrachtgever zich op als klant (van de expertise van de
        zelfstandige)?
      </li>
      <li>
        Of lijkt de zelfstandige meer op een vervanger die werkt binnen de
        structuur en processen van de opdrachtgever?
      </li>
      <li>
        Hoe wordt de zelfstandige gepresenteerd? Als externe specialist of als
        tijdelijke kracht?
      </li>
      <li>Wordt het resultaat van de opdracht benadrukt of de inspanning?</li>
    </ul>
    <p>
      Zoals je ziet gaat ook hier het resultaat relevant en vastlegging van
      belang.
    </p>
  </div>,
  <div key="20">
    <h2>Meerdere opdrachtgevers en zelfstandigheid</h2>
    <p>
      Het hebben van meerdere opdrachtgevers is een belangrijk kenmerk van
      ondernemerschap en speelt een cruciale rol in het bepalen van de
      zelfstandige status. Dit onderscheidt een zelfstandige van een werknemer
      en helpt om schijnzelfstandigheid te voorkomen.
    </p>
    <p>
      Het werken voor meerdere opdrachtgevers vermindert de economische
      afhankelijkheid van één klant. Dit is een teken van ondernemersrisico, wat
      typisch is voor zelfstandigheid.
    </p>
    <p>
      Wij stellen het volgende: de goede zakelijke relatie waar alle partijen
      het recht hebben die te behouden. Grote ondernemingen mogen afhankelijk
      zijn van één klant en mogen hun best doen om die te behouden. Dan dient
      dat ook voor de zelfstandig ondernemer te gelden, omdat er anders een
      rechtsongelijkheid bestaat.
    </p>
    <p>
      Zelfstandigen hebben het recht om zelf te bepalen voor wie zij werken, wat
      een kernaspect is van ondernemerschap.
    </p>
  </div>,
  <div key="21">
    <h2>Kamer van Koophandel-registratie en ondernemerschap</h2>
    <p>
      Een registratie bij de Kamer van Koophandel (KvK) is voor zelfstandigen
      van belang omdat het een formele erkenning van hun ondernemerschap biedt
      en toegang geeft tot wettelijke, zakelijke en praktische voordelen. Het
      helpt zelfstandigen zich te onderscheiden van werknemers en versterkt hun
      positie als ondernemer.
    </p>
    <p>
      Geen KvK-registratie maakt contracten ongeldig en kan de Belastingdienst
      boetes opleggen. Ook verliezen zelfstandigen toegang tot
      belastingvoordelen en ondernemersaftrekken.
    </p>
    <p>Eis dan ook een actuele KvK-registratie om zaken te doen.</p>
  </div>,
  <div key="22">
    <h2>BTW-nummer en zelfstandigheid</h2>
    <p>
      Als zelfstandige ben je verplicht om een BTW-nummer aan te vragen bij de
      Belastingdienst zodra je BTW-plichtig bent. Zonder BTW-nummer kun je geen
      BTW in rekening brengen, wat je zakelijk onwettig maakt. Het BTW-nummer is
      afzonderlijk van je inkomstenbelastingnummer en wordt specifiek gebruikt
      voor belasting met betrekking tot de toegevoegde waarde.
    </p>
    <p>
      Als je minder dan een bepaald bedrag aan BTW betaalt (momenteel € 20.000
      per jaar), kun je gebruikmaken van de Kleineondernemersregeling (KOR).
      Deze regeling maakt het mogelijk om geen BTW te heffen over je omzet,
      hoewel je dan ook geen BTW kunt terugvorderen op je zakelijke kosten. Het
      BTW-nummer blijft noodzakelijk om gebruik te maken van deze regeling.
    </p>
    <p>
      Je BTW-nummer moet op je facturen staan, zodat klanten kunnen controleren
      of de BTW correct is. Dit is een professionele vereiste voor zelfstandige
      ondernemers. Het ontbreken van een BTW-registratie of het niet voldoen aan
      de BTW-verplichtingen kan leiden tot boetes en naheffingen door de
      Belastingdienst.
    </p>
    <p>
      De controle van een BTW-nummer van een EU-land geregistreerd bedrijf kun
      je via VIES vinden. Dit is een systeem van de Europese Commissie dat
      BTW-nummers in de EU controleert.
    </p>
  </div>,
  <div key="23">
    <h2>DGA's en zelfstandigheid</h2>
    <p>
      De Belastingdienst behandelt DGA's in de regel niet als zelfstandige
      ondernemers, maar als werknemers van hun eigen BV (besloten vennootschap),
      wat inhoudt dat zij onder de loonbelasting vallen. Wat weleens de kop
      opsteekt is dat de Belastingdienst “door de BV heenkijkt” en de DGA als
      het ware scheidt van diens dienstverband binnen zijn onderneming en de
      arbeidsverhouding met een opdrachtgever apart bekijkt. Daarom is
      jurisprudentie belangrijk en ook is het van belang dat het Europees Hof
      hier iets van vindt, want een ongewild dubbel dienstverband, met alle
      fiscale consequenties, is niet hetgeen waar men als DGA op zit te wachten.
    </p>
    <h3>Jurisprudentie die relevant is:</h3>
    <h4>HR 27 juni 2014, ECLI:NL:HR:2014:1375 (DGA als werknemer)</h4>
    <p>
      In deze zaak oordeelde de Hoge Raad dat een DGA die zelf de enige
      aandeelhouder is van een BV en de controlerende macht heeft over de BV,
      doorgaans niet als zelfstandige ondernemer wordt aangemerkt, maar als
      werknemer van de BV. Het oordeel was gebaseerd op de loondienstrelatie die
      ontstaat door de gezagsverhouding, ondanks dat de DGA als zelfstandig
      ondernemer kan opereren binnen de BV.
    </p>
    <h4>HR 19 februari 2021, ECLI:NL:HR:2021:228 (DGA en zelfstandigheid)</h4>
    <p>
      In dit arrest bevestigde de Hoge Raad dat de beoordeling van het
      ondernemerschap van een DGA niet uitsluitend afhankelijk is van de formele
      structuur van de onderneming, maar dat gekeken moet worden naar de
      werkelijke omstandigheden. De vraag of een DGA als ondernemer wordt
      gezien, hangt af van de mate van zelfstandigheid, de aard van de
      werkzaamheden en of er een gezagsverhouding bestaat.
    </p>
    <p>
      <strong>Conclusie:</strong> De DGA kan als zelfstandige ondernemer worden
      aangemerkt als er geen sprake is van een gezagsverhouding, het inkomen
      voornamelijk uit eigen werkzaamheden wordt verdiend en er
      ondernemersrisico wordt gedragen.
    </p>
    <h4>HR 11 januari 2002, ECLI:NL:HR:2002 (ondernemerschap DGA)</h4>
    <p>
      De Hoge Raad oordeelde dat de DGA in dit geval als ondernemer moest worden
      aangemerkt voor de belastingheffing, ondanks de nauwe verbondenheid met de
      BV. Dit hangt af van de specifieke kenmerken van het ondernemerschap,
      zoals het dragen van ondernemersrisico en het in bezit zijn van een
      substantieel eigen vermogen in de BV.
    </p>
    <h4>Europese jurisprudentie</h4>
    <h4>Arrest C-47/02, Kay R. J. (2004)</h4>
    <p>
      Dit arrest ging over de vraag of een DGA in een lidstaat kan worden
      aangemerkt als zelfstandige ondernemer voor sociale zekerheidsdoeleinden.
      Het Europese Hof stelde dat lidstaten onder bepaalde omstandigheden een
      DGA als zelfstandige kunnen aanmerken, mits hij/zij ondernemersrisico
      loopt en de onafhankelijkheid van de bedrijfsvoering wordt gewaarborgd.
    </p>
    <h4>Arrest C-518/13 (2014)</h4>
    <p>
      In dit arrest oordeelde het Europese Hof dat een lidstaat een ondernemer
      (zelfstandige) moet respecteren in zijn rechten op basis van de Europese
      interne markt. Dit heeft implicaties voor DGA’s, omdat het Hof impliciet
      aangeeft dat in gevallen van zelfstandige economische activiteit (waarbij
      er geen sprake is van een gezagsverhouding of werknemersrelatie), de
      lidstaat de DGA als ondernemer moet behandelen, zelfs als het nationale
      recht een andere kwalificatie hanteert.
    </p>
    <h3>Samenvatting en conclusie</h3>
    <p>
      In Nederland wordt een DGA over het algemeen niet als zelfstandige
      ondernemer gezien door de Belastingdienst, maar als werknemer van de eigen
      BV, vooral als er sprake is van een gezagsverhouding. Jurisprudentie
      (zoals het arrest van de Hoge Raad in 2021) bevestigt dat het
      ondernemerschap van een DGA afhankelijk is van de feitelijke
      omstandigheden. Als een DGA daadwerkelijk ondernemersrisico draagt, een
      zekere mate van onafhankelijkheid heeft en geen gezagsverhouding
      ondergaat, kan deze als zelfstandige ondernemer worden geclassificeerd
      voor belastingdoeleinden.
    </p>
    <p>
      Europese jurisprudentie heeft implicaties voor de status van DGA's in een
      EU-context, waarbij het Europees Hof benadrukt dat de beoordeling van
      ondernemerschap niet alleen moet afhangen van nationale regelgeving, maar
      ook van de werkelijke zelfstandigheid van de ondernemer.
    </p>
    <p>
      Ons advies is gebaseerd op het gegeven dat een Besloten Vennootschap een
      duidelijke contra-indicatie is en zelfstandigheid benadrukt en bevestigt.
    </p>
  </div>,
  <div key="24">
    <h2>Declarabele uren-BV: Voor- en nadelen</h2>
    <p>
      Een "declarabele uren-BV" is een constructie waarbij een DGA
      (Directeur-grootaandeelhouder) wordt ingehuurd door zijn eigen BV voor het
      leveren van specifieke uren werk, vaak op basis van een uurtarief. De DGA
      kan als zelfstandige voor zijn eigen BV werken en de BV betaalt de DGA op
      basis van het aantal gewerkte uren.
    </p>
    <p>
      Deze constructie wordt vaak toegepast om de DGA in staat te stellen de
      voordelen van zelfstandig ondernemerschap te benutten, terwijl hij
      tegelijkertijd de fiscaal gunstige positie van een BV behoudt. De vraag is
      echter of de DGA in zo’n situatie werkelijk als zelfstandige ondernemer
      wordt gezien of niet en dit heeft juridische implicaties, vooral bij
      belasting- en arbeidsrechtelijke kwesties.
    </p>
    <h3>Voor- en nadelen voor de zelfstandige</h3>
    <ul>
      <li>
        Als zelfstandige kun je je eigen uurtarief bepalen en de hoeveelheid
        werk (uren) afstemmen op je wensen en beschikbare tijd. Dit biedt een
        zekere mate van flexibiliteit.
      </li>
      <li>
        De zelfstandige kan profiteren van belastingvoordelen die gekoppeld zijn
        aan ondernemerschap, zoals de self-employed tax credits, aftrekken van
        zakelijke kosten (bijvoorbeeld kantoorruimte, reiskosten, en andere
        bedrijfskosten), en het recht om te kiezen voor de fiscale voordelen van
        de inkomstenbelasting (zelfstandige aftrekken zoals de
        zelfstandigenaftrek en startersaftrek).
      </li>
      <li>
        Ondanks de samenwerking met een opdrachtgever heeft de zelfstandige
        theoretisch meer onafhankelijke controle over hoe hij het werk uitvoert
        dan een reguliere werknemer in loondienst.
      </li>
      <li>
        De zelfstandige kan voor verschillende klanten werken, hoewel hij
        mogelijk via de declarabele uren-BV meer contractuele zekerheid heeft
        van werk en inkomsten.
      </li>
    </ul>
    <h4>De minder leuke kant</h4>
    <ul>
      <li>
        De Belastingdienst kan de declarabele uren-BV als een
        loondienstconstructie beschouwen als er sprake is van een
        gezagsverhouding of onvoldoende ondernemersrisico.
      </li>
      <li>
        Zelfstandigen moeten hun eigen sociale zekerheid regelen (bijvoorbeeld
        een arbeidsongeschiktheidsverzekering en pensioen), wat in veel gevallen
        minder gunstig is dan de sociale zekerheden die een werknemer in
        loondienst ontvangt.
      </li>
      <li>
        Bij ziekte of andere onderbrekingen in de werkzaamheden heeft de
        zelfstandige vaak minder bescherming dan een werknemer, zoals
        loondoorbetaling bij ziekte of vakantiedagen.
      </li>
      <li>
        De afhankelijkheid van één opdrachtgever (de BV) kan leiden tot een
        scheve machtsverhouding, wat druk kan zetten als de opdrachtgever
        besluit om het aantal werkuren te verminderen of de samenwerking stop te
        zetten.
      </li>
    </ul>
    <h3>Voor- en nadelen voor de opdrachtgever</h3>
    <ul>
      <li>
        De opdrachtgever kan met een zelfstandige in een declarabele uren-BV
        werken zonder zich te hoeven binden aan een vast dienstverband. Dit
        biedt flexibiliteit in termen van de werkuren en het type werk dat
        verricht moet worden.
      </li>
      <li>
        Omdat de zelfstandige zelf verantwoordelijk is voor sociale
        verzekeringen, pensioen, en andere arbeidsvoorwaarden, zijn de totale
        kosten voor de opdrachtgever vaak lager dan bij een dienstverband.
      </li>
      <li>
        De opdrachtgever loopt minder risico op een arbeidsrelatie omdat de
        zelfstandige zelf verantwoordelijk is voor zijn werkzaamheden en
        betaling van belasting. Dit voorkomt het risico van loondienstclaims van
        de zelfstandige.
      </li>
    </ul>
    <h4>De nadelen voor de opdrachtgever</h4>
    <ul>
      <li>
        Als de Belastingdienst oordeelt dat er feitelijk sprake is van een
        gezagsverhouding tussen de opdrachtgever en de zelfstandige, kan de
        belastinginspecteur de constructie herkwalificeren als loondienst, wat
        kan leiden tot naheffingen van loonbelasting en sociale
        verzekeringspremies.
      </li>
      <li>
        De opdrachtgever kan door de afhankelijkheid van de zelfstandige voor
        specifieke taken mogelijk kwetsbaar worden voor plotselinge
        onderbrekingen of beëindigingen van de samenwerking.
      </li>
      <li>
        Als de zelfstandige daadwerkelijk als ondernemer werkt, heeft de
        opdrachtgever minder controle over hoe de taak wordt uitgevoerd, wat kan
        leiden tot problemen in de werkuitvoering als er misverstanden zijn over
        de gewenste werkmethodes of resultaten.
      </li>
      <li>
        Als de zelfstandige wordt aangemerkt als werknemer in plaats van
        ondernemer, kan de opdrachtgever geconfronteerd worden met hogere
        fiscale lasten, inclusief premies en boetes.
      </li>
    </ul>
    <h3>Risico’s</h3>
    <p>
      Deze constructie kan leiden tot forse naheffingen en boetes. De
      belangrijkste risico’s voor betrokkenen komen voort uit de mogelijkheid
      dat de Belastingdienst de arbeidsrelatie herkwalificeert van zelfstandige
      naar werknemer, met als gevolg dat de zelfstandige mogelijk belasting moet
      (terug)betalen over een dienstverband, inclusief sociale lasten en
      loonkosten. De opdrachtgever kan geconfronteerd worden met aanzienlijke
      extra kosten in de vorm van loonbelasting en sociale premies, plus
      mogelijke boetes voor het niet naleven van de fiscale regels.
    </p>
    <p>
      De zelfstandige moet voor zijn eigen sociale voorzieningen en pensioen
      zorgen, wat kan leiden tot een onvoldoende vangnet bij langdurige ziekte
      of arbeidsongeschiktheid. Voor de opdrachtgever brengt dit risico's met
      zich mee, zoals verstoringen in de bedrijfsvoering als de zelfstandige
      plotseling ziek wordt of besluit de samenwerking te beëindigen.
    </p>
  </div>,
  <div key="25">
    <h2>Zelfstandige werkwijze en resultaatgerichtheid</h2>
    <p>
      Als de opdrachtnemer zelf mag bepalen op welke manier hij of zij het
      gewenste resultaat behaalt, wijst dit op een zelfstandige werkwijze en een
      gebrek aan gezagsverhouding.
    </p>
  </div>,
  <div key="26">
    <h2>Zelfstandigheid en autonomie in werkindeling</h2>
    <p>
      Als de zelfstandige zelf kan bepalen hoe en wanneer hij of zij de taken
      invult, zonder gedetailleerde instructies van de opdrachtgever, wijst dit
      op zelfstandigheid ondanks de vervangende functie.
    </p>
    <p>
      Wanneer de opdrachtnemer zelf beslist over werktijden zonder vaste
      aanwezigheidseisen van de opdrachtgever, duidt dit op een afwezigheid van
      leiding en toezicht. Het zelf bepalen wanneer men zelf vrij heeft en
      neemt, duidt op het ontbreken van gezag.
    </p>
  </div>,
  <div key="27">
    <h2>Werkplekkeuze en zelfstandigheid</h2>
    <p>
      Als de opdrachtnemer zelf kan kiezen waar hij of zij werkt, bijvoorbeeld
      thuis of op eigen locatie, is dit een teken dat er geen gezagsverhouding
      is. Het hebben van een eigen werk-/kantoorlocatie is daarbij een
      duidelijke indicatie van zelfstandig ondernemer.
    </p>
  </div>,
  <div key="28">
    <h2>Vervanging en zelfstandigheid</h2>
    <p>
      De vraag of een zelfstandige zich mag laten vervangen hangt af van de
      specifieke omstandigheden van de opdracht en moet beoordeeld worden in het
      kader van de zelfstandige zonder personeel (ZP)-criteria die door de
      Belastingdienst en de wetgeving worden gesteld.
    </p>
    <h3>Ja, de zelfstandige mag zich laten vervangen</h3>
    <p>
      In de meeste gevallen kan een zelfstandige zich laten vervangen door een
      andere persoon, mits dit wordt ondersteund door de voorwaarden van de
      opdracht en de ondernemersstatus van de zelfstandige. Dit is vaak het
      geval wanneer de zelfstandige voldoende onafhankelijkheid en
      ondernemerschap vertoont en het werk niet afhankelijk is van zijn
      persoonlijke arbeid, maar van het resultaat of de uitvoering van het
      project. Zorg ervoor dat in de overeenkomst het recht tot vervanging wordt
      verleend!
    </p>
    <p>
      In andere vragen is duidelijk gemaakt dat resultaatgerichtheid, ontbreken
      van gezag, flexibiliteit en tekenen van ondernemerschap meegewogen moeten
      worden. Het feit dat een zelfstandige zich kan laten vervangen kan duiden
      op ondernemerschap: de zelfstandige is verantwoordelijk voor de uitvoering
      van het werk, maar heeft de mogelijkheid om dit werk uit te besteden aan
      anderen. Dit is gebruikelijk in bepaalde sectoren (bijvoorbeeld in de
      bouw, consultancy of IT), waar zelfstandigen hun expertise vaak met andere
      freelancers of medewerkers delen.
    </p>
    <h3>Nee, de zelfstandige mag zich niet laten vervangen</h3>
    <p>
      In sommige gevallen kan de zelfstandige niet vervangen worden, vooral als
      er sprake is van een gezagsverhouding of als de specifieke eisen van de
      opdracht vereisen dat de persoonlijke arbeid van de zelfstandige wordt
      uitgevoerd.
    </p>
    <p>
      Als de opdracht persoonlijke kennis of vaardigheden van de zelfstandige
      vereist, kan de opdrachtgever verwachten dat de zelfstandige zelf het werk
      uitvoert. In zulke gevallen wordt de zelfstandige ingehuurd voor zijn
      specifieke expertise en kan het niet worden uitbesteed zonder de kwaliteit
      of integriteit van het werk in gevaar te brengen. Dit geldt bijvoorbeeld
      voor opdrachten waarbij het essentieel is dat de opdrachtgever vertrouwt
      op de specifieke bekwaamheid van de zelfstandige (bijvoorbeeld als
      consultant of specialist).
    </p>
    <p>
      Dit is wel een contradictie op het gegeven dat diezelfde unieke kennis
      gezien kan worden als een wezenlijk onderscheid en daardoor basis kan zijn
      voor zelfstandig ondernemerschap.
    </p>
    <h3>Belastingdienst en ondernemerschap</h3>
    <p>
      De Belastingdienst eist dat de zelfstandige voldoende ondernemerschap
      vertoont om te worden beschouwd als een echte zelfstandige. Dit betekent
      dat de zelfstandige niet afhankelijk mag zijn van een opdrachtgever en
      moet kunnen aantonen dat hij zelfstandig risico loopt. Als de zelfstandige
      zich niet kan laten vervangen, kan dat erop wijzen dat hij te veel onder
      de controle van de opdrachtgever staat, wat de ondernemerschapsstatus kan
      ondermijnen.
    </p>
    <h3>Conclusie</h3>
    <p>
      De vraag of een zelfstandige zich mag laten vervangen is afhankelijk van
      de aard van de opdracht en de mate van zelfstandigheid die de zelfstandige
      heeft. Het ja-antwoord is van toepassing wanneer de zelfstandige als
      ondernemer werkt, de opdracht resultaatgericht is en er geen sprake is van
      een gezagsverhouding. Het nee-antwoord geldt wanneer de opdracht vereist
      dat de zelfstandige persoonlijk werk levert, er een gezagsverhouding
      bestaat of als de zelfstandige afhankelijk is van de opdrachtgever voor de
      uitvoering van de opdracht.
    </p>
    <p>
      De zelfstandige moet in staat zijn om zijn ondernemerschap te tonen, wat
      betekent dat hij, als ondernemer, zelf verantwoordelijkheid draagt voor de
      uitvoering van de opdracht en de mogelijkheid heeft om zich te laten
      vervangen, zolang de voorwaarden van de opdracht dit toelaten.
    </p>
  </div>,
  <div key="29">
    <h2>Projectgebonden verlengingen en zelfstandigheid</h2>
    <h3>Wanneer het antwoord Ja is</h3>
    <p>
      Wanneer het antwoord Ja is, betekent dit dat de samenwerking tussen de
      zelfstandige en de opdrachtgever altijd afhankelijk is van het
      eindresultaat van het huidige project en van de noodzaak voor nieuwe
      opdrachten om de samenwerking voort te zetten.
    </p>
    <h4>Consequenties voor de zelfstandige:</h4>
    <ul>
      <li>
        <strong>Bevestiging van ondernemerschap:</strong> Het feit dat de
        verlengingen afhankelijk zijn van nieuwe opdrachten, wijst erop dat de
        zelfstandige niet in loondienst is en geen verplichting heeft om door te
        werken voor de opdrachtgever.
      </li>
      <li>
        <strong>Ondernemersrisico:</strong> De zelfstandige draagt het
        ondernemersrisico. Hij is afhankelijk van de vraag naar zijn diensten en
        het succes van zijn opdrachten, wat kan bijdragen aan zijn
        onafhankelijkheid.
      </li>
      <li>
        <strong>Onzekerheid van werk:</strong> De zelfstandige kan niet rekenen
        op een doorlopende samenwerking met dezelfde opdrachtgever, afhankelijk
        van de behoeften van de opdrachtgever en de markt.
      </li>
    </ul>
    <h4>Consequenties voor de opdrachtgever:</h4>
    <ul>
      <li>
        <strong>Geen vast dienstverband:</strong> Het projectgebonden karakter
        van de verlengingen maakt duidelijk dat de opdrachtgever geen vast
        dienstverband aangaat met de zelfstandige.
      </li>
      <li>
        <strong>Flexibiliteit:</strong> De opdrachtgever kan steeds beoordelen
        of er behoefte is aan voortzetting van het project, afhankelijk van de
        projectbehoeften.
      </li>
      <li>
        <strong>Onzekerheid:</strong> Er is een risico dat de zelfstandige niet
        beschikbaar is voor toekomstige opdrachten, omdat deze mogelijk ook
        andere klanten heeft.
      </li>
    </ul>
    <h3>En als het antwoord Nee is?</h3>
    <p>
      Als verlengingen niet projectgebonden zijn en niet afhankelijk zijn van
      nieuwe opdrachten, kan dit wijzen op een meer structurele samenwerking
      tussen de zelfstandige en de opdrachtgever, met mogelijk implicaties voor
      de arbeidsrelatie.
    </p>
    <h4>Consequenties:</h4>
    <ul>
      <li>
        <strong>Herkwalificatie naar arbeidscontract:</strong> Als verlengingen
        structureel zijn en de zelfstandige niet de vrijheid heeft om zich aan
        andere opdrachten te wijden, kan de Belastingdienst de relatie
        herkwalificeren naar een arbeidscontract. Dit kan leiden tot naheffingen
        van loonbelasting en sociale premies.
      </li>
      <li>
        <strong>Afhankelijkheid:</strong> Partijen kunnen in een situatie
        terechtkomen waarin de zelfstandige voortdurend moet worden ingeroepen
        zonder een natuurlijke afronding van het project. Dit kan leiden tot
        afhankelijkheid en verlies van flexibiliteit.
      </li>
    </ul>
  </div>,
  <div key="30">
    <h2>Het belang van een looptijd in overeenkomsten</h2>
    <p>
      Het afspreken van een looptijd voorkomt dat de relatie tussen de
      zelfstandige en de opdrachtgever wordt gezien als een arbeidscontract, wat
      kan leiden tot herkwalificatie van de zelfstandige naar werknemer door de
      Belastingdienst. Als er geen vastgestelde looptijd is, kan de
      Belastingdienst oordelen dat er sprake is van een langdurige
      gezagsverhouding, wat kan resulteren in belastingheffing en sociale
      premies zoals bij een arbeidsovereenkomst.
    </p>
    <p>
      Door een looptijd vast te leggen, is er vaak de mogelijkheid om de
      samenwerking te vervangen door een nieuwe overeenkomst of te verlengen als
      beide partijen tevreden zijn met de resultaten van de samenwerking. Dit
      zorgt, indien gewenst, voor continuïteit in de werkrelatie en kan
      wederzijdse voordelen bieden.
    </p>
    <p>
      Het biedt beide partijen de mogelijkheid om de samenwerking te evalueren
      en op basis daarvan aan te passen of te beëindigen wanneer dat nodig is.
    </p>
  </div>,
  <div key="31">
    <h2>Vervanging en zelfstandigheid: Invloed van het Deliveroo-arrest</h2>
    <p>
      Een zelfstandige die specifiek aan een resultaat of project werkt zonder
      vast dienstverband, zelfs bij vervanging, heeft vaak een meer
      projectmatige en zelfstandige invulling dan een werknemer in loondienst.
    </p>
    <p>
      Vóór het Deliveroo-arrest werd nog wel eens gedacht dat er geen sprake kon
      zijn van een arbeidsovereenkomst als de opdrachtnemer zich vrij kon laten
      vervangen. In het Deliveroo-arrest heeft de Hoge Raad daarover een ander
      oordeel gegeven. Ook als de opdrachtnemer zich wél mag laten vervangen,
      kan er sprake zijn van een arbeidsovereenkomst. Dit geldt ook voor de
      vrijheid om een opdracht wel of niet te aanvaarden.
    </p>
    <p>
      Toch is het vervangingsrecht in een overeenkomst een indicator dat het
      niet om de persoon maar om het resultaat gaat. Stuurt men een vervanger en
      het resultaat is niet wat overeengekomen is, dan gaat het “voor eigen
      rekening en eigen risico” tellen en zal in iedere overeenkomst daar wel
      iets inzake aansprakelijkheid vastgelegd zijn.
    </p>
    <p>
      Dat vangt men op met een (bedrijfs)aansprakelijkheid die zich NIET in een
      dienstverband voordoet en daarmee een contra-indicatie is.
    </p>
  </div>,
  <div key="32">
    <h2>Inkoopvoorwaarden en zelfstandigheid</h2>
    <p>
      Weinig tot geen onderhandelingsruimte van de opdrachtnemer wijst op een
      arbeidsovereenkomst. Veel onderhandelingsruimte wijst juist op werken als
      zelfstandige.
    </p>
    <p>
      De inkoopvoorwaarden van een opdrachtgever bepalen vaak de manier waarop
      de samenwerking wordt ingericht en welke verplichtingen beide partijen
      hebben. Dit kan een zekere gezagsverhouding suggereren, afhankelijk van de
      inhoud van deze voorwaarden.
    </p>
    <p>
      Een echte zelfstandige zou idealiter in staat moeten zijn om
      inkoopvoorwaarden te onderhandelen en aan te passen aan zijn eigen
      bedrijfsvoering. Als een ZZP'er geen ruimte heeft om de voorwaarden te
      beïnvloeden of te onderhandelen, kan dit de zelfstandigheid ondermijnen,
      omdat het lijkt alsof de zelfstandige de opdrachtgever volgt in plaats van
      zelfstandig beslissingen te nemen.
    </p>
    <p>
      In dat kader zijn projectgebonden inkoopvoorwaarden, die alleen betrekking
      hebben op resultaatgerichte doelstellingen en de mate van vrijheid in de
      uitvoering behouden, minder problematisch, aangezien dit de
      zelfstandigheid van de zelfstandige niet beperkt. Als de inkoopvoorwaarden
      flexibiliteit bieden voor de zelfstandige om het werk naar eigen inzicht
      in te richten en zelf beslissingen te nemen over de uitvoering, dan zou
      dit de zelfstandigheid juist ondersteunen.
    </p>
    <p>
      Inkoopvoorwaarden kunnen ook bepalingen bevatten over exclusiviteit of de
      verplichting om langdurig voor dezelfde opdrachtgever te werken. Als een
      zelfstandige door deze voorwaarden gedwongen wordt om langdurig of
      exclusief voor één opdrachtgever te werken, kan dit de zelfstandigheid
      aantasten, omdat de zelfstandige dan afhankelijk wordt van één partij voor
      inkomen. Dit zou de zelfstandigheid onder druk zetten, vooral als er geen
      ruimte is om andere opdrachten of klanten te zoeken.
    </p>
    <p>
      Het accepteren van de inkoopvoorwaarden van een opdrachtgever is niet per
      definitie een probleem voor de zelfstandigheid van de zelfstandige, maar
      het is wel belangrijk om de aard van deze voorwaarden te analyseren. Als
      de voorwaarden ondernemerschap en zelfstandigheid ondersteunen
      (bijvoorbeeld door resultaatgerichtheid zonder gedetailleerde instructies
      over de uitvoering), is er weinig risico. Echter, als de inkoopvoorwaarden
      de werknemerschap of gezagsverhouding benadrukken (bijvoorbeeld door
      strikte richtlijnen en weinig ruimte voor eigen inbreng), kan dit de
      zelfstandigheid in gevaar brengen en leiden tot een herkwalificatie van de
      zelfstandige door de Belastingdienst als werknemer.
    </p>
    <p>
      Het is dus cruciaal dat de zelfstandige onderhandelingsruimte heeft en dat
      de voorwaarden de vrijheid om het werk naar eigen inzicht uit te voeren
      niet beperken.
    </p>
  </div>,
  <div key="33">
    <h2>De vraag: "In staat om andere opdrachten aan te nemen"</h2>
    <p>
      De vraag kan op verschillende manieren worden geïnterpreteerd, afhankelijk
      van de context en de specifieke omstandigheden van de zelfstandige en de
      opdracht. De term "in staat" kan namelijk op verschillende manieren worden
      begrepen, wat belangrijke implicaties heeft voor de beoordeling van de
      zelfstandigheid.
    </p>
    <h3>Mogelijke antwoorden: Ja en Nee</h3>
    <h4>
      "Ja" – De zelfstandige is in staat om andere opdrachten aan te nemen
    </h4>
    <p>
      Als de zelfstandige "in staat" is om andere opdrachten aan te nemen,
      betekent dit meestal dat de zelfstandige de vrijheid heeft om zijn eigen
      werk te plannen en in te delen, zonder dat de opdrachtgever een
      belemmering vormt voor andere zakelijke verplichtingen. Dit wijst
      doorgaans op de zelfstandigheid van de ZZP'er en voldoet waarschijnlijk
      aan de ZZP-criteria.
    </p>
    <h5>Overwegingen:</h5>
    <ul>
      <li>
        <strong>Vrijheid van werkrooster en planning:</strong> De zelfstandige
        kan zijn werk inrichten zoals hij wil, zonder dat de opdrachtgever
        exclusiviteit of onafgebroken beschikbaarheid eist.
      </li>
      <li>
        <strong>Geen exclusiviteitsvereisten:</strong> De zelfstandige heeft de
        vrijheid om diensten aan andere klanten en opdrachten te leveren, wat
        ondernemerschap ondersteunt.
      </li>
      <li>
        <strong>Flexibiliteit:</strong> Meerdere opdrachten aannemen wijst op
        ondernemerschap en vermindert de afhankelijkheid van één opdrachtgever.
      </li>
      <li>
        <strong>Zelfstandige beoordeling van opdrachten:</strong> De
        zelfstandige kan opdrachten kiezen, wat economische en zakelijke
        onafhankelijkheid aantoont.
      </li>
    </ul>
    <h4>
      "Nee" – De zelfstandige is niet in staat om andere opdrachten aan te nemen
    </h4>
    <p>
      Als de zelfstandige "niet in staat" is om andere opdrachten te accepteren,
      kan dit verschillende oorzaken hebben en mogelijk wijzen op een beperking
      van de zelfstandigheid.
    </p>
    <h5>Verklaringen:</h5>
    <ul>
      <li>
        <strong>Exclusiviteitsbeding:</strong> Een verplichting om exclusief
        voor de opdrachtgever te werken kan duiden op een gezagsverhouding, wat
        wijst op werknemerschap.
      </li>
      <li>
        <strong>Werklast:</strong> Als de opdracht fulltime werk vereist, kan
        dit fysieke of praktische beperkingen opleggen, maar dit is minder
        problematisch als het geen contractuele verplichting is.
      </li>
      <li>
        <strong>Opgelegde beperkingen:</strong> Als uurtarieven, werktijden of
        andere omstandigheden door de opdrachtgever worden bepaald en de
        zelfstandige beperken, kan dit de zelfstandigheid ondermijnen.
      </li>
    </ul>
    <h3>Interpretatie van "in staat"</h3>
    <p>
      De term "in staat" kan afhankelijk van de situatie verschillend worden
      geïnterpreteerd:
    </p>
    <ul>
      <li>
        <strong>Fysiek:</strong> De zelfstandige kan meer werk aannemen, maar de
        huidige opdracht vergt veel tijd en energie. Dit tast de zelfstandigheid
        niet aan als het niet door de opdrachtgever wordt beperkt.
      </li>
      <li>
        <strong>Juridisch:</strong> Beperkingen door exclusiviteitsbedingen of
        contractuele verplichtingen kunnen wel problematisch zijn en wijzen op
        een gezagsverhouding.
      </li>
    </ul>
    <h3>Conclusie</h3>
    <h4>Ja:</h4>
    <p>
      Als de zelfstandige in staat is om andere opdrachten aan te nemen, wijst
      dit op economische vrijheid en voldoet het waarschijnlijk aan de
      ZZP-criteria. De zelfstandigheid is niet in gevaar.
    </p>
    <h4>Nee:</h4>
    <p>
      Als de zelfstandige niet in staat is om andere opdrachten aan te nemen
      door contractuele verplichtingen of een gezagsverhouding, kan dit wijzen
      op een beperking van de zelfstandigheid. Dit kan leiden tot
      herkwalificatie als werknemer.
    </p>
    <p>
      In beide gevallen is het belangrijk om de specifieke voorwaarden van de
      opdracht en de praktische mogelijkheden van de zelfstandige te onderzoeken
      om te bepalen of er sprake is van echte zelfstandigheid of juist een
      afhankelijkheid die een arbeidsrelatie suggereert.
    </p>
  </div>,
];
