import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

export default function ComplianceFactoryAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ background: "#fff" }} elevation={0}>
        <Toolbar sx={{ background: "#fff", borderBottom: "0.5px solid #eee" }}>
          <img
            src="https://www.compliancefactory.nl/wp-content/uploads/2021/05/NieuwLogo.png"
            style={{ maxHeight: 40 }}
            alt="The Compliance Factory Logo"
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
