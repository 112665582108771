import { CootjeLogo, HeroPage, RowCentered } from "./components";
import { Box, Typography } from "@mui/material";
import { ApplicationThemeProvider } from "./theme";
import { useState } from "react";
import { Option, QuestionAndAnswer, QuestionAndOptions } from "./models";
import QuestionAndAnswerField from "./components/QuestionAndAnswer";
import EmailForm from "./components/EmailForm";
import MainQuestionsReport from "./components/MainQuestionsReport";
import ContraQuestionsReport from "./components/ContraQuestionsReport";
import ExplanationDialog from "./components/ExplanationDialog";
import { contraExplanations, mainExplanations } from "./explanations";
import ComplianceFactoryAppBar from "./components/AppBar";
import { Helmet } from "react-helmet";

export const mainQuestions = require("./questions.json");
export const contraQuestions = require("./contra-questions.json");

function App() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mainQuestionsStarted, setMainQuestionsStarted] = useState(false);
  const [mainQuestionsFinished, setMainQuestionsFinished] = useState(false);
  const [contraQuestionsStarted, setContraQuestionsStarted] = useState(false);
  const [contraQuestionsFinished, setContraQuestionsFinished] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<
    QuestionAndAnswer[]
  >([]);

  const handleMainQuestionAnswerSubmit = (
    question: QuestionAndOptions,
    optionAnswered: Option
  ) => {
    const questionAndAnswer: QuestionAndAnswer = {
      questionAndOptions: question,
      answer: optionAnswered,
    };

    setCurrentQuestion(currentQuestion + 1);
    setQuestionsAndAnswers([...questionsAndAnswers, questionAndAnswer]);

    // Move to the next question or handle completion
    if (currentQuestion >= mainQuestions.length - 1) {
      setMainQuestionsStarted(false);
      setMainQuestionsFinished(true);
      setCurrentQuestion(0);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setQuestionsAndAnswers(questionsAndAnswers.slice(0, -1));
    }
  };

  const handleContraQuestionAnswerSubmit = (
    question: QuestionAndOptions,
    optionAnswered: Option
  ) => {
    const questionAndAnswer: QuestionAndAnswer = {
      questionAndOptions: question,
      answer: optionAnswered,
    };

    setCurrentQuestion(currentQuestion + 1);
    setQuestionsAndAnswers([...questionsAndAnswers, questionAndAnswer]);

    // Move to the next question or handle completion
    if (currentQuestion >= contraQuestions.length - 1) {
      setContraQuestionsStarted(false);
      setContraQuestionsFinished(true);
    }
  };

  return (
    <ApplicationThemeProvider>
      <Helmet>
        <title>Compliance Checker</title>
        <meta
          name="description"
          content="Compliance Checker. Een vragenlijst om te checken of je nog ZZP-er kunt blijven."
        />
        <meta
          name="keywords"
          content="Compliance, Checker, ZZP, DBA, DBA-wetgeving, Deliveroo-arrest, ZZP-check"
        />
        <meta property="og:title" content="Compliance Checker" />
        <meta
          property="og:description"
          content="Compliance Checker. Een vragenlijst om te checken of je nog ZZP-er kunt blijven."
        />
        <meta property="og:url" content="https://doedecheck.cootje.com/" />
      </Helmet>
      <ComplianceFactoryAppBar />
      <HeroPage>
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            position: "relative",
            padding: "8px 8px",
          }}
        >
          <RowCentered
            sx={{ width: "100%", justifyContent: "center", mb: 2, mt: 8 }}
          >
            <CootjeLogo />
          </RowCentered>

          {!mainQuestionsStarted &&
            !contraQuestionsStarted &&
            !mainQuestionsFinished &&
            !contraQuestionsFinished && (
              <EmailForm
                email={email}
                setEmail={setEmail}
                name={name}
                setName={setName}
                setMainQuestionsStarted={setMainQuestionsStarted}
              />
            )}

          {(mainQuestionsStarted || contraQuestionsStarted) && (
            <>
              <QuestionAndAnswerField
                questionNumber={currentQuestion}
                maxQuestions={
                  !mainQuestionsFinished
                    ? mainQuestions.length
                    : contraQuestions.length
                }
                question={
                  !mainQuestionsFinished
                    ? mainQuestions[currentQuestion]
                    : contraQuestions[currentQuestion]
                }
                onSubmitAnswer={
                  !mainQuestionsFinished
                    ? handleMainQuestionAnswerSubmit
                    : handleContraQuestionAnswerSubmit
                }
                goToPreviousQuestion={goToPreviousQuestion}
                currentQuestion={currentQuestion}
              />
              <Box sx={{ mt: 4 }}>
                <ExplanationDialog
                  question={
                    contraQuestionsStarted
                      ? contraQuestions[currentQuestion].question
                      : mainQuestions[currentQuestion].question
                  }
                  explanation={
                    contraQuestionsStarted
                      ? contraExplanations[currentQuestion]
                      : mainExplanations[currentQuestion]
                  }
                />
              </Box>
            </>
          )}

          {mainQuestionsFinished &&
            !contraQuestionsStarted &&
            !contraQuestionsFinished && (
              <MainQuestionsReport
                setContraQuestionsStarted={setContraQuestionsStarted}
                setMainQuestionsFinished={setMainQuestionsFinished}
                setCurrentQuestion={setCurrentQuestion}
                fullname={name}
                email={email}
                questionsAndAnswers={questionsAndAnswers}
                setEmail={setEmail}
              />
            )}

          {mainQuestionsFinished && contraQuestionsFinished && (
            <ContraQuestionsReport
              fullname={name}
              email={email}
              questionsAndAnswers={questionsAndAnswers}
              setEmail={setEmail}
            />
          )}

          <Box sx={{ flex: 1 }} />
          <Typography variant="caption" sx={{ p: 2 }}>
            Disclaimer: Dit middel wordt ter beschikking gesteld als hulpmiddel
            bij het kwalificeren van arbeidsrelaties. De gebruiker ervan maakt
            zelf de benodigde afwegingen. Wij kunnen niet bepalen of de inhoud
            dan wel de uitkomsten juist zijn en aanvaarden op geen enkele wijze
            aansprakelijkheid voor schade, van welke aard dan ook, die direct of
            indirect voortvloeit uit het gebruik ervan.
          </Typography>
        </Box>
      </HeroPage>
    </ApplicationThemeProvider>
  );
}

export default App;
