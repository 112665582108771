import { Box, Typography } from "@mui/material";
import React from "react";
import { RowCentered } from "./Row";

export const CootjeImg = React.memo((props: { assetName: string }) => (
  <img
    src={`/assets/${props.assetName}`}
    alt="Logo"
    style={{ maxWidth: 40, height: "auto" }}
  />
));

export function CootjeLogo() {
  return (
    <Box sx={{ display: "flex", flex: 0, alignItems: "center" }}>
      <Box>
        <CootjeImg assetName="cootje_logo.png" />
      </Box>
      <CootjeTitle title="Cootje" />
    </Box>
  );
}

export function CootjeTitle(props: { title: string; subtitle?: string }) {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      sx={{ mb: 2 }}
    >
      <Box
        display="flex"
        flexDirection={"row"}
        alignItems={"baseline"}
        sx={{ mb: 0.5 }}
      >
        <Typography
          variant="overline"
          sx={{
            fontSize: "1.4rem",
            lineHeight: 0.8,
            letterSpacing: 0,
            mb: 0.2,
          }}
          color={"primary"}
        >
          {props.title}
        </Typography>
        <Box
          sx={{
            borderRadius: "50%",
            width: 6,
            height: 6,
            background: "#f39c12",
            mb: 0.2,
            ml: 0.2,
          }}
        />
      </Box>
      <Typography
        variant="overline"
        sx={{ fontSize: "0.8rem", lineHeight: 0.8, letterSpacing: 0 }}
        color={"secondary"}
      >
        {props.subtitle}
      </Typography>
    </Box>
  );
}
