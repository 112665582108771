import { QuestionAndOptions } from "./models";

export const calculateMaxScore = (
  questions: QuestionAndOptions[],
) => {
  return questions.reduce((acc, question) => {
    const optionValues = question.options.map((option) =>
      Object.values(option)[0]
    );

    return acc + Math.max(...optionValues);
  }, 0);
}