import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Lottie from "react-lottie-player";
import { CootjeLogo, CootjeTitle } from "./CootjeLogo";

const animation = require("../animations/signcontract.json");

export function HeroPage(props: {
  children?: React.ReactNode;
  topAlignment?: boolean;
}) {
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        md={5}
        lg={6}
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          display: { xs: "none", md: "flex", lg: "flex" },
          overflow: "auto",
          height: "100vh",
        }}
      >
        <Box>
          <Box
            sx={{
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CootjeTitle
              title="Doe de check"
              subtitle="Voldoe jij aan de ZZP en DBA criteria?"
            />
            <Lottie
              loop
              play
              style={{ width: 330, height: 300, zIndex: -1, maxWidth: "90%" }}
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              animationData={animation}
            />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        lg={6}
        component={Paper}
        elevation={1}
        square
        sx={{
          height: "100vh",
          overflow: "auto",
          display: "flex",
          pt: 4,
        }}
      >
        {props.children}
      </Grid>
    </Grid>
  );
}
