import { Box, Container, Typography } from "@mui/material";
import { calculateMaxScore } from "../utils";
import { QuestionAndAnswer, QuestionAndOptions } from "../models";
import GradientBar from "./GradientBar";
import { SaveButton } from "./Buttons";
import { RowCentered } from "./Row";
import {
  getScoreColorFunc,
  getScoreDescription,
  getScoreLabel,
  getScoreResult,
} from "../utils/scoreResult";
import { useState } from "react";
import { calculateScore } from "../utils/calculate_score";
import { TextInput } from "./inputs";

const mainQuestions: QuestionAndOptions[] = require("../questions.json");

const maxMainScore = calculateMaxScore(mainQuestions);

interface MainQuestionsReportProps {
  setContraQuestionsStarted: (started: boolean) => void;
  setMainQuestionsFinished: (finished: boolean) => void;
  setCurrentQuestion: (questionIndex: number) => void;
  fullname: string;
  questionsAndAnswers: QuestionAndAnswer[];
  email: string;
  setEmail: (email: string) => void;
}

const MainQuestionsReport: React.FC<MainQuestionsReportProps> = ({
  setContraQuestionsStarted,
  setMainQuestionsFinished,
  setCurrentQuestion,
  fullname,
  questionsAndAnswers,
  email,
  setEmail,
}) => {
  const [sentPdf, setSentPdf] = useState(false);

  const handleClick = () => {
    setContraQuestionsStarted(true);
    setMainQuestionsFinished(true);
    setCurrentQuestion(0);
  };

  const handleSendReport = async () => {
    const totalScoreLabel =
      percentage < 25 ? "rood" : percentage < 75 ? "oranje" : "groen";
    fetch(`${window.location.origin}/api/report/sendpdfreport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Messages: [
          {
            Role: "user",
            Content:
              JSON.stringify(questionsAndAnswers) +
              ", Totaalscore: " +
              totalScoreLabel,
          },
        ],
        email: email,
        result: totalScoreLabel,
        fullname: fullname,
      }),
    }).catch((error) => {
      console.error("Error:", error);
    });

    setSentPdf(true);
  };

  const percentage = (calculateScore(questionsAndAnswers) / maxMainScore) * 100;
  const resultaat = getScoreResult(percentage);
  const scoreLabel = getScoreLabel(resultaat);
  const showReportButton = scoreLabel !== "Oranje";

  return (
    <Container maxWidth="sm">
      <GradientBar percentage={percentage} />

      <RowCentered sx={{ mt: 1 }}>
        <Typography variant="h6" fontWeight={"bold"} sx={{ mr: 1 }}>
          Score:
        </Typography>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          color={getScoreColorFunc(resultaat)(1)}
        >
          {getScoreLabel(resultaat)}
        </Typography>
      </RowCentered>

      <Typography variant="body1" sx={{ mt: 2 }}>
        {getScoreDescription(resultaat)}
      </Typography>

      {showReportButton && (
        <Typography variant="body1" fontWeight={"bold"} sx={{ mt: 4 }}>
          {sentPdf
            ? `Het rapport is verstuurd naar: ${email}`
            : "Ga door met de extra vragen, of ontvang een uitgebreid rapport met uitleg en advies over de overeenkomst."}
        </Typography>
      )}

      {showReportButton && !sentPdf && (
        <RowCentered sx={{ mt: 4 }}>
          <Typography>Het rapport wordt verstuurd naar:</Typography>
          <TextInput
            placeholder={email}
            value={email}
            onChange={setEmail}
            sx={{
              ml: 2,
              flexGrow: 1,
              minWidth: 0,
              width: "100%",
              maxWidth: "300px",
            }}
          />
        </RowCentered>
      )}

      <RowCentered
        sx={{ mt: 4 }}
        justifyContent="space-between"
        flexWrap="wrap"
        gap={1}
      >
        <SaveButton
          onComplete={handleClick}
          error={null}
          label="Ga door met de extra vragen"
        />

        {!sentPdf && showReportButton && (
          <Box>
            <SaveButton
              onComplete={handleSendReport}
              error={null}
              label="Rapport opvragen"
              sx={{ ml: 1 }}
            />
          </Box>
        )}
      </RowCentered>
      <Typography
        variant="body2"
        sx={{
          fontStyle: "italic",
          mt: 4,
        }}
      >
        Neem bij twijfel of onduidelijkheid contact op met The Compliance
        Factory via{" "}
        <a href="https://www.compliancefactory.nl/contact/">onze website</a>,
        mail ons op:{" "}
        <a href="mailto:mail@compliancefactory.nl">mail@compliancefactory.nl</a>
        , of bel ons op: <a href="tel:+31850063900">+31 (0)85 0063900</a>.
      </Typography>
    </Container>
  );
};

export default MainQuestionsReport;
