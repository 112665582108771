import React, { useState } from "react";
import { Abc, EmailTwoTone } from "@mui/icons-material";
import { Checkbox, TextInput } from "./inputs";
import { SaveButton } from "./Buttons";
import { emailValidation } from "../validations/email";
import { RowCentered } from "./Row";
import { Box, Container } from "@mui/material";

interface EmailFormProps {
  email: string;
  setEmail: (email: string) => void;
  name: string;
  setName: (name: string) => void;
  setMainQuestionsStarted: (started: boolean) => void;
}

const EmailForm: React.FC<EmailFormProps> = ({
  email,
  setEmail,
  name,
  setName,
  setMainQuestionsStarted,
}) => {
  const handleSave = () => {
    setMainQuestionsStarted(true);
  };

  const [checked, setChecked] = useState(false);
  const validEmail = emailValidation(email) === undefined;
  const validName = Boolean(name.length > 2);
  const disabled = !validEmail || !validName || !checked;

  return (
    <Container maxWidth="xs">
      <RowCentered sx={{ mb: 2, width: "100%" }}>
        <TextInput
          label="Naam"
          placeholder="Naam"
          icon={Abc}
          onChange={setName}
          value={name}
          sx={{ flex: 1 }}
        />
      </RowCentered>
      <RowCentered sx={{ mb: 2, width: "100%" }}>
        <TextInput
          label="Email"
          placeholder="Enter email"
          icon={EmailTwoTone}
          value={email}
          onChange={setEmail}
          validator={() => emailValidation(email)}
          onSubmit={handleSave}
          sx={{ flex: 1 }}
        />
      </RowCentered>
      <RowCentered sx={{ mb: 1 }}>
        <Checkbox
          checked={checked}
          title="Mijn email mag worden opgeslagen."
          onChange={() => setChecked(!checked)}
        />
      </RowCentered>
      <RowCentered>
        <Box sx={{ flex: 1 }} />
        <SaveButton
          disabled={disabled}
          onComplete={handleSave}
          error={null}
          label="Beginnen"
        />
      </RowCentered>
    </Container>
  );
};

export default EmailForm;
