import { DeleteTwoTone, FileUploadTwoTone } from "@mui/icons-material";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import { ButtonInput } from "./inputs/FileInput";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useState } from "react";
import { primaryLight } from "../theme";

export function ContainedButton(props: LoadingButtonProps) {
    return <LoadingButton {...props} sx={{ background: props.disabled ? '#eee' : primaryLight, px: 2, ...props.sx }} color="primary" />
}

export function DeleteButton({
    disabled,
    onClick,
    sx,
    variant
}: {
    variant?: "text" | "outlined" | "contained";
    disabled?: boolean;
    onClick: () => void;
    sx?: SxProps<Theme>
}) {
    return <Button sx={sx} disabled={disabled} onClick={onClick} variant={variant ?? "outlined"} color="error">
        <DeleteTwoTone />
        <Typography variant="overline">Verwijderen</Typography>
    </Button>

}

export function ImportPDFButton({
    loading,
    onImportPdf
}: {
    loading: boolean;
    onImportPdf: (file: File) => void;
}) {
    return <ButtonInput
        icon={FileUploadTwoTone}
        key={Math.random()}
        fileTypes={["pdf", "eml", "msg", "docx"]}
        loading={loading}
        placeholder="Importeer vanuit CV"
        onChange={onImportPdf} />
}

export function SaveButton({
    error, label, onComplete, loading, sx, disabled
}: {
    error: string | null;
    loading?: boolean;
    label: string;
    onComplete?: () => void;
    disabled?: boolean;
    sx?: SxProps<Theme>
}) {
    return <ContainedButton disabled={disabled} sx={sx} loading={loading} onClick={onComplete} variant="text">
        {!error && label}
        {error && <Typography variant="overline" color="error">{error}</Typography>}
    </ContainedButton>
}

export function RelationButton(props: {
    loading?: boolean,
    relation?: boolean,
    onAdd: () => void,
    onRemove: () => void
}) {
    if (props.relation)
        return <LoadingButton loading={props.loading} variant="text" color="error" onClick={props.onRemove}>Verwijderen</LoadingButton>

    return <LoadingButton loading={props.loading} variant="text" color="primary" onClick={props.onAdd}>Koppelen</LoadingButton>
}

export function PrevNextButtons({
    onNext, onPrev, showNext, showPrev, nextDisabled, prevDisabled, variant
}: {
    onNext: () => void;
    onPrev: () => void;
    showNext: boolean;
    showPrev: boolean;
    nextDisabled?: boolean;
    prevDisabled?: boolean;
    variant?: "outlined" | "contained" | "text";
}) {
    return <Box sx={{ display: 'flex', width: '100%', my: 2, maxWidth: 800 }}>
        {showPrev && <Button sx={{ fontSize: 12 }} onClick={onPrev} variant={variant ?? "outlined"} color="primary" disabled={prevDisabled}>Vorige</Button>}
        {showNext && <Button sx={{ fontSize: 12, ml: showPrev ? 1 : 0 }} onClick={onNext} variant={variant ?? "outlined"} color="primary" disabled={nextDisabled}>Volgende</Button>
        }
    </Box>
}

export function PreviewButton({
    onClick,
    disabled
}: {
    onClick: () => any;
    disabled?: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        if (!onClick) return;

        setLoading(true);
        await onClick()
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    return <LoadingButton disabled={disabled} loading={loading} variant="text" color="primary" onClick={handleClick}>Bekijken</LoadingButton>
}
