import React from "react";

interface GradientBarProps {
  percentage: number;
}

const GradientBar: React.FC<GradientBarProps> = ({ percentage }) => {
  // Ensure percentage is within 0-100
  const validPercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "600px",
        height: "4px",
        background:
          "linear-gradient(to right, #ff0000, #e5970f, #e5970f, #00ff0f)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-15px",
          left: `calc(${validPercentage}% - 7.5px)`,
          width: "0",
          height: "0",
          borderLeft: "7.5px solid transparent",
          borderRight: "7.5px solid transparent",
          borderTop: "10px solid black",
        }}
      ></div>
    </div>
  );
};

export default GradientBar;
