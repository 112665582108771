import React from "react";
import { QuestionAndOptions } from "../models";
import { Row, RowCentered } from "./Row";
import { Option } from "../models";
import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import { errorColor, successColor } from "../theme";
import { ArrowBack } from "@mui/icons-material";

interface QuestionAndAnswerProps {
  questionNumber: number;
  maxQuestions: number;
  question: QuestionAndOptions;
  onSubmitAnswer: (question: QuestionAndOptions, answer: Option) => void;
  goToPreviousQuestion: () => void;
  currentQuestion: number;
}

const QuestionAndAnswerField: React.FC<QuestionAndAnswerProps> = ({
  question,
  maxQuestions,
  questionNumber,
  onSubmitAnswer,
  goToPreviousQuestion,
  currentQuestion,
}) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ height: 200 }}>
        <Row sx={{ alignItems: "center" }}>
          {currentQuestion > 0 && (
            <IconButton onClick={goToPreviousQuestion}>
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="overline" sx={{ textAlign: "start" }}>
            Vraag {questionNumber + 1} van {maxQuestions}
          </Typography>
        </Row>
        <Typography variant="h6" sx={{ textAlign: "start", lineHeight: 1.3 }}>
          {question.question}
        </Typography>
      </Box>
      <RowCentered
        sx={{ width: "100%", justifyContent: "center", my: 2, gap: 4 }}
      >
        <RowCentered>
          <Button
            sx={{
              background: successColor(0.1),
              color: successColor(1),
              width: 100,
            }}
            onClick={() => onSubmitAnswer(question, question.options[0])}
          >
            Ja
          </Button>
          <Box sx={{ width: 24 }} />
          <Button
            sx={{
              background: errorColor(0.1),
              color: errorColor(1),
              width: 100,
            }}
            onClick={() => onSubmitAnswer(question, question.options[1])}
          >
            Nee
          </Button>
        </RowCentered>
      </RowCentered>
    </Container>
  );
};

export default QuestionAndAnswerField;
